// 越南语
export default {
    home: {
        text1: 'CHÀO MỪNG ĐẾN VỚI',
        SXF: 'SXF',
        text2: `Đây là một hành động khẩn cấp để quan tâm và bảo vệ sinh thái biển. Tại đây, bạn sẽ tìm hiểu về
                        cách bảo vệ môi trường sinh thái biển và đa dạng sinh học biển. Ngoài ra, bạn sẽ có
                        cơ hội tham gia vào các hoạt động từ thiện và quảng bá của chúng tôi, góp phần bảo vệ
                        sức khỏe của đại dương và hành tinh.`,
        text3: 'Liên Hệ Chúng Tôi',
        text4: 'Chia sẻ đến:',
        text5: `Tầm nhìn của chúng tôi là cải thiện sức khỏe đại dương toàn cầu, bảo vệ hệ sinh thái biển và
                duy trì đa dạng sinh học biển.`,
        text6: 'Tin mới nhất',
        text7: `Tham gia cùng chúng tôi trong Tuần lễ Cá mập 2023 và khám phá thế giới của nghiên cứu mới và quản lý
                                cá mập bền vững.`,
        text8: 'XEM THÊM>>',

        p1: 'Trang chủ › Giới thiệu về chúng tôi',
        p2: `Để có không gian phát triển toàn cầu, Quỹ Sphere X, được hướng dẫn bởi phương châm "Bắt đầu
                    từ Góc Nhìn Của Đại Dương," đã chọn một ý tưởng mạnh mẽ và chuyển hóa nó thành ba trụ cột chính
                    hành động. Các trụ cột này sẽ cùng thúc đẩy giáo dục, nhận thức, tham gia và
                    thay đổi hành vi. Chúng cũng sẽ thực hiện các hành động để thúc đẩy các nền kinh tế xanh đổi mới
                    cũng như bảo vệ và phục hồi đại dương. Trong quá trình này, bằng cách tăng cường dân chủ hóa
                    và mở rộng quy mô, chúng tôi sẽ cố gắng sử dụng bền vững các đại dương.`,
        p3: `Trang chủ › Hành động Mỹ`,
        p4: `Hành động Bảo vệ Đại dương của Công dân Mỹ`,
        p5: `Trang chủ › Danh sách các loài biển có nguy cơ tuyệt chủng`,
        p6: `Danh sách các loài biển có nguy cơ tuyệt chủng`,
        p7: `CÁC LOÀI BIỂN NGUY CƠ TUYỆT CHỦNG`,
        p8: `Chào mừng đến với SXF`,
        p9: `Hải cẩu tu sĩ Hawaii`,
        p10: `Trang chủ › THAM GIA CHÚNG TÔI`,
        p11: `SXF (Tên hiệp hội)`,
        p12: `Sphere X Fund (SXF) là một tổ chức từ thiện phi lợi nhuận có trụ sở tại Hoa Kỳ, dành riêng để bảo vệ hệ sinh thái biển và bảo tồn đa dạng sinh học biển. SXF đã từ bỏ các phương thức quảng cáo truyền thống và phát triển các chiến lược quảng bá riêng của mình. Bằng cách tận dụng công nghệ và sức mạnh cộng đồng, SXF mang đến những phần thưởng hữu hình cho những người bảo vệ môi trường, từ đó nâng cao nhận thức và hành động vì bảo tồn biển.`,
        p13: `Trang chủ › SINH THÁI BIỂN`,
        p14: `Sinh thái biển là tập hợp các mối quan hệ giữa các sinh vật biển và tương tác của chúng với môi trường xung quanh. Các hệ sinh thái biển bao gồm các thành phần như nước biển, địa hình đáy biển, thảm cỏ biển, rạn san hô và sinh vật phù du, tạo thành một hệ sinh thái phức tạp và phong phú. Sinh thái biển đóng vai trò quan trọng trong cân bằng sinh thái của Trái Đất bằng cách cung cấp thức ăn, oxy và tài nguyên, đồng thời ảnh hưởng đến khí hậu, hệ thống lưu thông toàn cầu và chu trình carbon. Bảo vệ hệ sinh thái biển là điều cần thiết để duy trì cân bằng sinh thái của Trái Đất và bền vững cho môi trường con người. Hiện nay, sinh thái biển đối mặt với nhiều thách thức và vấn đề, chủ yếu xoay quanh các chủ đề như biến đổi khí hậu, khai thác quá mức và ô nhiễm biển.`,

        navhome: "TRANG CHỦ",
        navabout: "GIỚI THIỆU VỀ CHÚNG TÔI",
        navam: "HÀNH ĐỘNG MỸ",
        navjoin: "THAM GIA CHÚNG TÔI",
        navend: "CÁC LOÀI BIỂN NGUY CƠ TUYỆT CHỦNG",
        navmar: "SINH THÁI BIỂN",
        User: 'Trung Tâm Người Dùng',

        home: 'TRANG CHỦ',
        Action: 'Trong Hành Động',
        Sharenow: "Chia sẻ ngay",
        ReviewResult: 'Kết quả Xem lại',
        Inbox: 'Hộp Thư',
        Account: 'Tài Khoản',
    },
    account: {
        t1: 'Tài Khoản Của Tôi',
        t2: 'Mã mời:',
        t3: 'Người mời:',
        t4: 'Đội Của Tôi',
        t5: 'Chức Danh Của Tôi',
        t6: 'Thẻ Của Tôi',
        t7: 'Đổi Mật Khẩu',
        t8: 'Ngôn ngữ',
        t9: 'Giới Thiệu Phiên Bản',
        t10: 'Xóa Tài Khoản',
        t11: 'Đăng Xuất',

        text: 'Chi tiết Thẻ',

        ai1: '› Ai',
        ai2: `Hiện tại chỉ có giao dịch USDT (Trc20).
Sau khi chuyển khoản, bạn phải quay lại trang trước (chi tiết thẻ) để tải lên ảnh chụp màn hình giao dịch để hoàn tất thanh toán!`,
        ai3: `Địa chỉ giao hàng (sao chép địa chỉ dưới đây)`,

        c1: ` › Thẻ Của Tôi`,
        c2: 'Số: x',
        c3: 'Thanh toán: Tiền mã hóa',
        c4: 'Ngày:',
        c5: 'Đang Xem xét',
        c6: 'Đã Chấp nhận',
        c7: 'Bị Từ chối',
        c8: 'Số thẻ:',
        c9: 'Mã kích hoạt:',
        c10: 'Lý do từ chối:',

        d1: '› Chi tiết Thẻ',
        d2: `Giá Gốc:`,
        d3: `Giảm Giá: `,
        d4: `Thanh Toán Thực Tế`,
        d5: `Thanh toán tiền mã hóa`,
        d6: `Dịch vụ khách hàng Al`,
        d7: `Vui lòng nhấp vào dịch vụ khách hàng Al để nhận địa chỉ giao hàng. Sau khi hoàn tất thanh toán, quay lại đây để tải lên ảnh chụp màn hình.`,
        d8: `Mua`,
        d9: 'Nhập giá trị hash',

        a1: `› Hủy Tài Khoản`,
        a2: `Hủy Tài Khoản`,
        a3: `Mật khẩu:`,
        a4: `Vui lòng nhập mật khẩu`,
        a5: `Vui lòng nhập mật khẩu hiện tại của tài khoản để xác nhận hủy tài khoản. Sau khi tài khoản bị hủy, bạn sẽ không thể đăng nhập. Xin lưu ý`,
        a6: `Xác nhận`,

        cp1: ` › Đổi Mật Khẩu`,
        cp2: `Đặt mật khẩu mới`,
        cp3: `Mật khẩu cũ:`,
        cp4: `Vui lòng nhập mật khẩu cũ`,
        cp5: `Mật khẩu mới:`,
        cp6: `Vui lòng nhập mật khẩu mới`,
        cp7: `Xác nhận Mật khẩu:`,
        cp8: 'Vui lòng nhập mật khẩu để xác nhận',
        cp9: 'Xác nhận',

        l1: ` › Cài đặt ngôn ngữ`,

        mt1: ` › Đội Của Tôi`,
        mt2: `Liên kết mời`,
        mt3: `Sao chép liên kết`,
        mt4: `Chia sẻ hàng ngày`,
        mt5: `Mời Đối Tác`,
        mt6: `Tên người dùng`,
        mt7: `Cấp độ`,
        mt8: `Thời gian`,

        tle1: ` › Chức Danh Của Tôi`,
        tle2: `Liên kết mời`,
        tle3: `Giảm giá 9.2`,
        tle4: 'Chia sẻ hàng ngày',
        tle5: 'Mời Đối Tác',
        tle6: `Tổng Điểm`,
        tle7: 'Vai Trò',
        tle8: `1. Người bảo vệ - Đồng, cần s1% điểm và được hưởng s2% giảm giá.`,
        tle9: `2. Người bảo vệ - Bạc, cần s1% điểm và được hưởng s2% giảm giá.`,
        tle10: `3. Người bảo vệ - Vàng, cần s1% điểm và được hưởng s2% giảm giá.`,
        tle11: `4. Người bảo vệ - Kim cương, cần s1% điểm và được hưởng s2% giảm giá.`,
        tle12: `5. Người bảo vệ - Tối cao, cần s1% hoặc nhiều hơn điểm và được hưởng s2% giảm giá.`,
        tle13: `Cách để tích lũy điểm`,
        tle14: `Mỗi khi bạn chia sẻ nội dung liên quan đến bảo vệ đại dương và tải ảnh chụp màn hình lên Sphere X Fund, cũng như khi bạn mua thẻ quà tặng Amazon, bạn sẽ nhận được 1 điểm. Khi điểm của bạn đạt đến một mức nhất định, bạn sẽ được hưởng mức giảm giá lớn hơn. Hãy tham gia cùng chúng tôi và đóng góp vào việc bảo vệ đại dương!`,
        
        v1: ` › Giới Thiệu Phiên Bản`,
        v2: `Mô tả Phiên Bản`,
        v3: `Số Phiên Bản`,
    },

    inaction: {
        t1: 'Bước 1: Vui lòng lưu áp phích sự kiện của chúng tôi.',
        t2: 'Nhấn và giữ ảnh áp phích để lưu vào album.',
        t3: `Bước 2: Vui lòng chia sẻ ảnh này trên các nền tảng xã hội của chúng tôi để mời thêm
                        mọi người tham gia vào bảo vệ sinh thái biển`,
        t4: 'Chia sẻ đến:',
        t5: `Mỗi lần chia sẻ của bạn thể hiện sự quan tâm đến sinh thái biển. SXF
                        cảm ơn bạn vì hành động tốt đẹp của bạn!`,
        t6: `Bước 3: Vui lòng gửi bằng chứng ảnh chụp màn hình của các áp phích sự kiện của chúng tôi
                        đã chia sẻ trên các nền tảng xã hội. Bạn
                        có thể tham gia sự kiện sau khi hoàn tất xét duyệt. Xin cảm ơn!`,
        t7: 'Vui lòng tải ảnh lên',
        t8: 'Chia sẻ ngay',
    },

    inactX: {
        rejected: 'Bị Từ Chối',
        under: 'Đang Xem Xét',
        approved: 'Đã Chấp Nhận',
    },

    inbox: {
        t1: 'Mua Ngay',
    },

    login: {
        Username: 'Tên Đăng Nhập',
        LoginPassword: 'Mật Khẩu Đăng Nhập',
        Registeraccount: 'Đăng ký tài khoản',
        Retrievepassword: 'Khôi phục mật khẩu',
        login: 'Đăng Nhập',
        loginSS: 'Đăng nhập thành công',
        t1: 'Xác Nhận Mật Khẩu',
        t2: 'Vui lòng nhập tên đầy đủ',
        t3: 'Số điện thoại',
        t4: 'Email',
        t5: 'mã',
        t6: 'Mã mời',
        t7: 'Đăng Ký',
        t8: 'Hủy',
        t9: 'Trang Đăng Ký',
        t10: 'Khôi phục Mật Khẩu',
    },

    foo: {
        t1: 'Tham gia cộng đồng toàn cầu của chúng tôi',
        t2: `Đăng ký vào danh sách gửi thư của chúng tôi và kết nối với chúng tôi trên các phương tiện truyền thông xã hội. Cùng nhau, chúng ta
                        đang làm thay đổi thế giới để bảo vệ động vật.`,
        t3: `Đăng ký vào danh sách gửi thư của chúng tôi`,
        t4: `Nhận tin tức và blog mới nhất qua email.`,
        t5: `Đăng ký`,
        t6: `Tải xuống ứng dụng`,
        t7: `Trải nghiệm tốt hơn, nhiều ưu đãi hơn`,
        t8: `Hiệp hội Bảo tồn Đại dương Thế giới, một công ty trách nhiệm hữu hạn có trụ sở tại`,
        t9: `Hoa Kỳ với số đăng ký 20211058723`,
        t10: `4255 South Buckley Road #1296, Aurora, CO 80013, Hoa Kỳ`,
    },

    More: 'Xem Thêm',
    onBuy: "Bạn hiện không đủ điều kiện mua hàng, vui lòng chia sẻ và quay lại sau",
	onBuyed: "Hôm nay bạn đã mua rồi, vui lòng quay lại vào ngày mai",
    copyC: "Sao chép thành công",
	copyS: "Sao chép thất bại",
};