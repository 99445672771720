// 中繁
export default {
    home: {
        text1: '歡迎來到',
        SXF: 'SXF',
        text2: `這是一項緊急行動，旨在保護和關注海洋生態。在這裡，您將學習如何保護海洋生態環境和海洋生物多樣性。此外，您還有機會參加我們的慈善和宣傳活動，為海洋和地球的健康貢獻一份力量。`,
        text3: '聯繫我們',
        text4: '分享至：',
        text5: `我們的願景是改善全球海洋健康，保護海洋生態系統，並維護海洋生物多樣性。`,
        text6: '最新消息',
        text7: `加入我們的2023鯊魚週，深入了解最新研究和可持續的鯊魚管理。`,
        text8: '更多>>',

        p1: '首頁 › 關於我們',
        p2: `為了在全球範圍內獲得發展空間，Sphere X Fund基金會以其座右銘「從海洋的視角出發」為指導，選擇了一個強大的理念並將其轉化為三個主要行動支柱。這些支柱將共同促進教育、意識提升、參與和行為改變。它們還將採取行動，進一步推動創新藍色經濟以及海洋的保護和恢復。在此過程中，通過加強民主化和擴大規模，我們將努力實現海洋的可持續利用。`,
        p3: `首頁 › 美國行動`,
        p4: `全美公民海洋守護行動`,
        p5: `首頁 › 瀕危海洋物種清單`,
        p6: `瀕危海洋物種清單`,
        p7: `瀕危海洋物種`,
        p8: `歡迎來到SXF`,
        p9: `夏威夷僧海豹`,
        p10: `首頁 › 加入我們`,
        p11: `SXF（協會名稱）`,
        p12: `Sphere X Fund (SXF) 是一家位於美國的非營利慈善信託組織，致力於保護海洋生態系統和維護海洋生物多樣性。SXF放棄了傳統的廣告方式，並開發了其專有的推廣策略。通過技術和社區力量，為環保倡導者提供實際獎勵，從而提升對海洋保護的意識和行動。`,
        p13: `首頁 › 海洋生態`,
        p14: `海洋生態學指的是各種海洋生物之間的相互關係及其與周圍環境的相互作用。海洋生態系統包括海水、海床地形、海草床、珊瑚礁和浮游生物等組成部分，形成一個複雜而豐富的生態系統。海洋生態在地球生態平衡中發揮著至關重要的作用，提供食物、氧氣和資源，並影響氣候、全球循環系統和碳循環。保護海洋生態系統對維持地球的生態平衡和人類環境的可持續性至關重要。目前，海洋生態面臨一系列挑戰和問題，主要圍繞全球變暖、過度捕撈和海洋污染等主題進行介紹。`,

        navhome: "首頁",
        navabout: "關於我們",
        navam: "美國行動",
        navjoin: "加入我們",
        navend: "瀕危海洋物種",
        navmar: "海洋生態",
        User: '用戶中心',

        home: '首頁',
        Action: '行動中',
        Sharenow: "立即分享",
        ReviewResult: '審核結果',
        Inbox: '收件箱',
        Account: '帳戶',
    },
    account: {
        t1: '我的帳戶',
        t2: '邀請碼：',
        t3: '邀請人：',
        t4: '我的團隊',
        t5: '我的稱號',
        t6: '我的卡片',
        t7: '更改密碼',
        t8: '語言',
        t9: '版本介紹',
        t10: '刪除帳戶',
        t11: '登出',

        text: '卡片詳情',

        ai1: '› Ai',
        ai2: `目前僅支持USDT（Trc20）交易。
轉賬後，請返回上一頁（卡片詳情）上傳交易截圖以完成付款！`,
        ai3: `發貨地址（複製以下地址）`,

        c1: ` › 我的卡片`,
        c2: '號碼：x',
        c3: '付款方式：加密貨幣',
        c4: '日期：',
        c5: '審核中',
        c6: '通過',
        c7: '被拒',
        c8: '卡號：',
        c9: '啟動碼：',
        c10: '拒絕原因：',

        d1: '› 卡片詳情',
        d2: `原價：`,
        d3: `折扣：`,
        d4: `實際付款`,
        d5: `加密貨幣付款`,
        d6: `人工客服`,
        d7: `請點擊人工客服以獲取收貨地址。完成付款後，返回此處上傳截圖。`,
        d8: `購買`,
        d9: '輸入哈希值',

        a1: ` › 帳戶註銷`,
        a2: `帳戶註銷`,
        a3: `密碼：`,
        a4: `請輸入密碼`,
        a5: `請輸入當前帳戶密碼以確認註銷帳戶。帳戶註銷後，您將無法登錄，請注意`,
        a6: `確認`,

        cp1: ` › 更改密碼`,
        cp2: `設置新密碼`,
        cp3: `舊密碼：`,
        cp4: `請輸入舊密碼`,
        cp5: `新密碼：`,
        cp6: `請輸入新密碼`,
        cp7: `確認密碼：`,
        cp8: '請輸入確認密碼',
        cp9: '確認',

        l1: ` › 語言設置`,

        mt1: ` › 我的團隊`,
        mt2: `邀請鏈接`,
        mt3: `複製鏈接`,
        mt4: `每日分享`,
        mt5: `邀請夥伴`,
        mt6: `用戶名`,
        mt7: `級別`,
        mt8: `時間`,

        tle1: ` › 我的稱號`,
        tle2: `邀請鏈接`,
        tle3: `9.2折`,
        tle4: '每日分享',
        tle5: '邀請夥伴',
        tle6: `總積分`,
        tle7: '角色',
        // tle8: `1. 守護者 - 鐵，所需積分為0-60，可享9.2折。`,
        tle8: `1. 保護者 - 青銅，需要 s1% 積分，並享受 s2% 折扣。`,
        tle9: '2. 保護者 - 銀，需要 s1% 積分，並享受 s2% 折扣。',
        tle10: `3. 保護者 - 金，需要 s1% 積分，並享受 s2% 折扣。`,
        tle11: `4. 保護者 - 鑽石，需要 s1% 積分，並享受 s2% 折扣。`,
        tle12: `5. 保護者 - 至尊，需要 s1% 或以上積分，並享受 s2% 折扣。`,
        tle13: `如何獲得積分`,
        tle14: `每次您分享與海洋保護相關的內容並將截圖上傳至 Sphere X Fund，並購買亞馬遜禮品卡時，您將獲得 1 積分。當您的積分達到一定門檻時，您將享有更大的折扣。加入我們，為海洋保護貢獻一份力量！`,
        
        
        v1: ` › 版本介紹`,
        v2: `版本描述`,
        v3: `版本號`,
    },

    inaction: {
        t1: '步驟 1：請保存我們的活動海報。',
        t2: '長按海報照片以將其保存到相冊。',
        t3: `步驟 2：請將此照片分享到我們的社交平台，以邀請更多
                        人參與海洋生態保護`,
        t4: '分享到：',
        t5: `您每次分享都是對海洋生態的關注，SXF感謝您的善行！`,
        t6: `步驟 3：請提交您分享活動海報的截圖證明。審核完成後，您即可參加活動。感謝！`,
        t7: '請上傳圖片',
        t8: '立即分享',
    },

    inactX: {
        rejected: '被拒',
        under: '審核中',
        approved: '已通過',
    },

    inbox: {
        t1: '立即購買',
    },

    login: {
        Username: '用戶名',
        LoginPassword: '登錄密碼',
        Registeraccount: '註冊帳戶',
        Retrievepassword: '找回密碼',
        login: '登錄',
        loginSS: '登錄成功',
        t1: '確認密碼',
        t2: '請輸入全名',
        t3: '手機號碼',
        t4: '電子郵件',
        t5: '驗證碼',
        t6: '邀請碼',
        t7: '註冊',
        t8: '取消',
        t9: '註冊頁面',
        t10: '找回密碼',
    },

    foo: {
        t1: '加入我們的全球社區',
        t2: `註冊我們的郵件列表並在社交媒體上與我們連接。我們共同致力於保護動物。`,
        t3: `註冊我們的郵件列表`,
        t4: `通過電子郵件接收最新消息和博客。`,
        t5: `註冊`,
        t6: `下載應用`,
        t7: `更佳體驗，更多折扣`,
        t8: `世界海洋保護協會，一家在美國註冊的有限公司`,
        t9: `註冊號20211058723`,
        t10: `4255 South Buckley Road #1296, Aurora, CO 80013, United States`,
    },

    More: '更多',
    onBuy: "您目前不符合購買條件，請分享後再回來",
    onBuyed: "您今天已購買，請明天再來",
    copyC: "複製成功",
    copyS: "複製失敗",
};