import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);


const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/pages/tabs/home.vue'),
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('@/pages/home/about.vue'),
    },
    {
        path: '/american',
        name: 'American',
        component: () => import('@/pages/home/american.vue'),
    },
    {
        path: '/join',
        name: 'Join',
        component: () => import('@/pages/home/join.vue'),
    },
    {
        path: '/end',
        name: 'End',
        component: () => import('@/pages/home/end.vue'),
    },
    {
        path: '/endDes',
        name: 'EndDes',
        component: () => import('@/pages/home/endDes.vue'),
    },
    {
        path: '/mar',
        name: 'Mar',
        component: () => import('@/pages/home/mar.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/pages/login/login.vue'),
    },
    {
        path: '/reg',
        name: 'Reg',
        component: () => import('@/pages/login/reg.vue'),
    },
    {
        path: '/retrievepwd',
        name: 'Retrievepwd',
        component: () => import('@/pages/login/retrievepwd.vue'),
    },
    {
        path: '/account',
        name: 'Account',
        component: () => import('@/pages/tabs/account.vue'),
    },
    {
        path: '/Inaction',
        name: 'Inaction',
        component: () => import('@/pages/tabs/Inaction.vue'),
    },
    {
        path: '/InactX',
        name: 'InactX',
        component: () => import('@/pages/tabs/InactX.vue'),
    },
    {
        path: '/Inbox',
        name: 'Inbox',
        component: () => import('@/pages/tabs/Inbox.vue'),
    },
    {
        path: '/team',
        name: 'Team',
        component: () => import('@/pages/account/team.vue'),
    },
    {
        path: '/card',
        name: 'Card',
        component: () => import('@/pages/account/card.vue'),
    },
    {
        path: '/cea',
        name: 'Cea',
        component: () => import('@/pages/account/cea.vue'),
    },
    {
        path: '/changepwd',
        name: 'Changepwd',
        component: () => import('@/pages/account/changepwd.vue'),
    },
    {
        path: '/lang',
        name: 'Lang',
        component: () => import('@/pages/account/lang.vue'),
    },
    {
        path: '/title',
        name: 'Title',
        component: () => import('@/pages/account/title.vue'),
    },
    {
        path: '/version',
        name: 'Version',
        component: () => import('@/pages/account/version.vue'),
    },
    {
        path: '/cardDes',
        name: 'CardDes',
        component: () => import('@/pages/account/cardDes.vue'),
        // meta: { keepAlive: true }
    },
    {
        path: '/Ai',
        name: 'Ai',
        component: () => import('@/pages/account/Ai.vue'),
    },
    {
        path: '/systemTip',
        name: 'SystemTip',
        component: () => import('@/pages/tabs/systemTip.vue'),
    },
]


const router = new VueRouter({
    mode: 'history',
    routes,

});

// 重写 push 方法，捕获 NavigationDuplicated 错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => {
        if (err.name !== 'NavigationDuplicated') {
            throw err; // 如果不是冗余导航错误，则抛出错误
        }
    });
};

// 重写 replace 方法，捕获 NavigationDuplicated 错误
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch((err) => {
        if (err.name !== 'NavigationDuplicated') {
            throw err;
        }
    });
};


export default router;