<template>
    <footer class="footer">
        <div class="footer_top" v-if="type == 'home'">
            <div class="pc flex">
                <div class="footer_top_text">
                    <div class="flex newT">
                        <img src="../assets/home/link.png" alt="">
                        <span>{{  $t('foo.t1') }}</span>
                    </div>
                    <p class="newText">{{  $t('foo.t2') }}</p>
                </div>
                <div class="footer_top_login">
                    <div class="flex newT">
                        <img src="../assets/home/Mail.png" alt="" style="width: 35.4px;height: 23.8px;">
                        <span>{{  $t('foo.t3') }}</span>
                    </div>
                    <p class="newText">{{  $t('foo.t4') }}</p>
                    <div class="btn" @click="toPageReg">{{  $t('foo.t5') }}</div>
                </div>
                <div class="footer_top_btn">
                    <div class="flex newT">
                        <img src="../assets/home/dow.png" alt="" style="width: 26.8px;height: 26.8px;">
                        <span>{{  $t('foo.t6') }}</span>
                    </div>
                    <p class="newText">{{  $t('foo.t7') }}</p>
                    <div class="btnList">
                        <img src="../assets/home/apple.png" alt="">
                        <img src="../assets/home/google.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="footer_bottom">
            <div class="pc flex">
                <img src="../assets/home/botlogo.png" alt="">
                <div class="footer_bottom_text">
                    <p>{{  $t('foo.t8') }}</p>
                    <p>{{  $t('foo.t9') }}</p>
                    <p>{{  $t('foo.t10') }}</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import router from '@/router';
export default {
    name: 'Footer', // 组件名称
    props: {
        type: {
            type: String,
            default: 'home'
        }
    },
    methods: {
        toPageReg() {
            router.replace('/reg')
        }
    },
    data() {
        return {
            
        };
    },
}
</script>


<style lang="less" scoped>
.footer {
    color: #fff;

    .footer_top {
        // height: 287px;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        background: url('../assets/home/footer_top.png') no-repeat;
        background-size: cover;

        .pc {
            box-sizing: border-box;
            padding: 72px 0 64px;
            gap: 40px;
        }

        .newT {
            align-items: center;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 23px;

            img {
                width: 31.6px;
                height: 26.3px;
                margin-right: 5px;
            }
        }

        .newText {
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
        }

        .footer_top_text {
            // margin-right: 20px;
            flex: 1;
        }

        .footer_top_login {
            flex: 1;

            // margin: 0 20px;
            .btn {
                background: #F5AE18;
                width: 80%;
                height: 54.6px;
                border-radius: 8px;
                text-align: center;
                line-height: 54.6px;
                margin-top: 27px;
                cursor: pointer;
            }
        }

        .footer_top_btn {
            flex: 1;

            // margin-left: 20px;
            .btnList {
                display: flex;
                align-items: center;
                margin-top: 25px;

                img {
                    width: 158px;
                    height: 52px;
                    margin-right: 20px;
                    cursor: pointer;
                }
            }
        }
    }

    .footer_bottom {
        height: 204.5px;
        background: #121947;

        .pc {
            height: 100%;
            align-items: center;
            justify-content: space-between;
        }

        img {
            width: 202px;
            height: 79px;
        }

        .footer_bottom_text {
            text-align: right;
            font-weight: 400;
            font-size: 16px;
            line-height: 36px;
        }
    }
}
</style>