// 日语
export default {
    home: {
        text1: 'ようこそ',
        SXF: 'SXF',
        text2: `これは、海洋エコロジーを保護するための緊急の行動です。ここでは、海洋生態環境と海洋生物多様性の保護について学ぶことができます。また、私たちの慈善活動やプロモーション活動に参加して、海や地球の健康のために貢献する機会があります。`,
        text3: 'お問い合わせ',
        text4: '共有する：',
        text5: `私たちのビジョンは、世界中の海洋の健康を改善し、海洋生態系を保護し、
                海洋生物の多様性を維持することです。`,
        text6: '最新ニュース',
        text7: `2023年のシャークウィークに参加して、新しい研究や持続可能なサメ管理の世界に飛び込みましょう。`,
        text8: 'もっと見る>>',

        p1: 'ホーム › 私たちについて',
        p2: `グローバルに発展するために、Sphere X Fund財団は「海洋の視点から始める」というモットーを掲げ、強力なアイデアを選び、これを3つの主要な行動の柱に変えました。これらの柱は、教育、意識、参加、行動変化の促進に貢献します。また、革新的なブルーエコノミーの実現や海洋の保護と再生を促進します。この過程で、民主化を強化し、規模を拡大することで、海洋の持続可能な利用を目指します。`,
        p3: `ホーム › アメリカ行動`,
        p4: `アメリカ市民のためのオーシャンガーディアンアクション`,
        p5: `ホーム › 絶滅危惧海洋種リスト`,
        p6: `絶滅危惧海洋種リスト`,
        p7: `絶滅危惧海洋種`,
        p8: `ようこそSXFへ`,
        p9: `ハワイアンモンクシール`,
        p10: `ホーム › 参加する`,
        p11: `SXF（団体名）`,
        p12: `Sphere X Fund (SXF)は、アメリカ合衆国に拠点を置く非営利の慈善信託組織であり、海洋生態系の保護と海洋生物多様性の維持に努めています。SXFは従来の広告方法を放棄し、独自のプロモーション戦略を開発しました。技術とコミュニティの力を活用して、環境保護活動家に具体的な報酬を提供し、海洋保護に対する意識と行動を強化しています。`,
        p13: `ホーム › 海洋生態`,
        p14: `海洋生態学とは、さまざまな海洋生物間の相互関係やそれらの周囲の環境との相互作用を指します。海洋生態系には、海水、海底地形、海草床、サンゴ礁、プランクトンなどの要素が含まれており、複雑で豊かな生態系を形成しています。海洋生態は、食料や酸素、資源を提供し、気候や地球規模の循環システム、炭素サイクルに影響を与えることで、地球の生態バランスにおいて重要な役割を果たしています。海洋生態系の保護は、地球の生態バランスを維持し、人間環境の持続可能性を確保するために不可欠です。現在、海洋生態は地球温暖化、乱獲、海洋汚染などのテーマを中心に一連の課題や問題に直面しています。`,

        navhome: "ホーム",
        navabout: "私たちについて",
        navam: "アメリカ行動",
        navjoin: "参加する",
        navend: "絶滅危惧海洋種",
        navmar: "海洋生態",
        User: 'ユーザーセンター',

        home: 'ホーム',
        Action: '活動中',
        Sharenow: "今すぐ共有",
        ReviewResult: 'レビュー結果',
        Inbox: '受信箱',
        Account: 'アカウント',
    },
    account: {
        t1: 'マイアカウント',
        t2: '招待コード：',
        t3: '招待者：',
        t4: '私のチーム',
        t5: '私の称号',
        t6: '私のカード',
        t7: 'パスワードを変更する',
        t8: '言語',
        t9: 'バージョンの紹介',
        t10: 'アカウント削除',
        t11: 'ログアウト',

        text: 'カードの詳細',

        ai1: '› Ai',
        ai2: `現在、USDT（Trc20）トランザクションのみ利用可能です。
送金後、前のページ（カード詳細）に戻って、トランザクションのスクリーンショットをアップロードして
支払いを完了してください！`,
        ai3: `配送先住所（以下の住所をコピー）`,

        c1: ` › マイカード`,
        c2: '番号：x',
        c3: '支払い：暗号通貨',
        c4: '日付：',
        c5: '審査中',
        c6: '承認済み',
        c7: '拒否されました',
        c8: 'カード番号：',
        c9: 'アクティベーションコード：',
        c10: '拒否理由：',

        d1: '› カードの詳細',
        d2: `元の価格：`,
        d3: `割引：`,
        d4: `実際の支払い`,
        d5: `暗号通貨支払い`,
        d6: `AIカスタマーサービス`,
        d7: `収穫先住所を取得するにはAIカスタマーサービスをクリックしてください。支払いが完了したら、ここに戻ってスクリーンショットをアップロードしてください。`,
        d8: `購入`,
        d9: 'ハッシュ値を入力',

        a1: `› アカウントキャンセル`,
        a2: `アカウントキャンセル`,
        a3: `パスワード：`,
        a4: `パスワードを入力してください`,
        a5: `アカウントのキャンセルを確認するため、現在のアカウントのパスワードを入力してください。アカウントをキャンセルすると、ログインできなくなりますのでご注意ください。`,
        a6: `確認`,

        cp1: ` › パスワード変更`,
        cp2: `新しいパスワードを設定`,
        cp3: `古いパスワード：`,
        cp4: `古いパスワードを入力してください`,
        cp5: `新しいパスワード：`,
        cp6: `新しいパスワードを入力してください`,
        cp7: `パスワードの確認：`,
        cp8: '確認用のパスワードを入力してください',
        cp9: '確認',

        l1: ` › 言語設定`,

        mt1: ` › 私のチーム`,
        mt2: `招待リンク`,
        mt3: `リンクをコピー`,
        mt4: `日々の共有`,
        mt5: `パートナーを招待`,
        mt6: `ユーザー名`,
        mt7: `レベル`,
        mt8: `時間`,

        tle1: ` › 私の称号`,
        tle2: `招待リンク`,
        tle3: `9.2割引`,
        tle4: '日々の共有',
        tle5: 'パートナーを招待',
        tle6: `合計ポイント`,
        tle7: '役割',
        tle8: `1. ガーディアン - ブロンズ、s1% のポイントが必要で、s2% の割引を受けられます。`,
        tle9: `2. ガーディアン - シルバー、s1% のポイントが必要で、s2% の割引を受けられます。`,
        tle10: `3. ガーディアン - ゴールド、s1% のポイントが必要で、s2% の割引を受けられます。`,
        tle11: `4. ガーディアン - ダイヤモンド、s1% のポイントが必要で、s2% の割引を受けられます。`,
        tle12: `5. ガーディアン - スプリーム、s1% 以上のポイントが必要で、s2% の割引を受けられます。`,
        tle13: `ポイントの獲得方法`,
        tle14: `海洋保護に関するコンテンツをシェアし、スクリーンショットを Sphere X Fund にアップロードするたび、または Amazon ギフトカードを購入するたびに、1 ポイントが獲得できます。ポイントが一定のしきい値に達すると、さらに大きな割引を受けられます。私たちと一緒に参加し、海洋保護に貢献しましょう！`,
        
        v1: ` › バージョンの紹介`,
        v2: `バージョンの説明`,
        v3: `バージョン番号`,
    },

    inaction: {
        t1: 'ステップ1：私たちのイベントポスターを保存してください。',
        t2: 'ポスター写真を長押ししてアルバムに保存します。',
        t3: `ステップ2：この写真をソーシャルプラットフォームで共有して、より多くの人々を海洋生態保護に招待しましょう。`,
        t4: '共有する：',
        t5: `あなたの共有は海洋生態への関心を示すものです。SXFはあなたの親切な行動に感謝します！`,
        t6: `ステップ3：私たちのイベントポスターをソーシャルプラットフォームで共有した証拠のスクリーンショットを提出してください。レビューが完了したら、イベントに参加できます。ありがとうございます！`,
        t7: '画像をアップロードしてください',
        t8: '今すぐ共有',
    },

    inactX: {
        rejected: '拒否されました',
        under: '審査中',
        approved: '承認済み',
    },

    inbox: {
        t1: '今すぐ購入',

    },

    login: {
        Username: 'ユーザー名',
        LoginPassword: 'ログインパスワード',
        Registeraccount: 'アカウント登録',
        Retrievepassword: 'パスワードを取得する',
        login: 'ログイン',
        loginSS: '正常にサインインしました',
        t1: 'パスワードの確認',
        t2: '氏名を入力してください',
        t3: '電話番号',
        t4: 'メール',
        t5: 'コード',
        t6: '招待コード',
        t7: '登録',
        t8: 'キャンセル',
        t9: '登録ページ',
        t10: 'パスワードを取得する',
    },

    foo: {
        t1: 'グローバルコミュニティに参加する',
        t2: `私たちのメーリングリストに登録し、ソーシャルメディアでつながりましょう。共に世界を動かし、動物を保護しましょう。`,
        t3: `私たちのメーリングリストに登録する`,
        t4: `最新ニュースとブログをメールで受け取る`,
        t5: `登録する`,
        t6: `アプリをダウンロード`,
        t7: `より良い体験、より多くの割引`,
        t8: `ワールドオーシャン保護協会、アメリカを拠点とする有限会社`,
        t9: `登録番号20211058723`,
        t10: `4255 South Buckley Road #1296, Aurora, CO 80013, アメリカ合衆国`,
    },

    More: 'もっと見る',
    onBuy: "現在、購入条件を満たしていません。共有してから再度お越しください",
	onBuyed: "本日はすでに購入済みです。明日またお越しください",
    copyC: "コピー成功",
	copyS: "コピー失敗",
};