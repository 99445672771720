import Vue from 'vue'
import App from './App.vue'
import './components';
import router from './router'; // 导入路由
import i18n from './locale/index'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';



Vue.prototype.$message = ElementUI.Message;

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
