import Vue from 'vue';

// 动态导入 components 文件夹下的所有 .vue 文件
const requireComponent = require.context(
    '.', // 当前目录
    false, // 不查询子目录
    /\.vue$/ // 匹配 .vue 结尾的文件
);

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName);

    // 提取文件名并去掉路径和扩展名
    let componentName = fileName
        .replace(/^.\//, '') // 去掉 './'
        .replace(/\.\w+$/, ''); // 去掉扩展名

    // 去掉 Ui- 前缀（如果存在）
    componentName = componentName.replace(/^Ui-/, '');

    // 注册为全局组件
    Vue.component(componentName, componentConfig.default || componentConfig);
});