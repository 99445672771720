import Vue from 'vue'
import VueI18n from 'vue-i18n';

import en from './en';
import cn from './cn';
import fa from './fa';
import ja from './ja';
import ti from './ti';
import hd from './hd';
import ko from './ko';
import ta from './ta';
import In from './In';

Vue.use(VueI18n);

const messages = {
    en,
    cn,
    fa,
    ja,
    ti,
    hd,
    ko,
    ta,
    In
};

const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'en',
    fallbackLocale: 'en',
    messages,
});

export default i18n;