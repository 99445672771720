// 印度尼西亚
export default {
    home: {
        text1: 'SELAMAT DATANG DI',
        SXF: 'SXF',
        text2: `Ini adalah tindakan darurat untuk peduli dan melindungi ekologi laut. Di sini Anda akan belajar tentang
                        melindungi lingkungan ekologi laut dan keanekaragaman hayati laut. Selain itu, Anda akan memiliki
                        kesempatan untuk berpartisipasi dalam kegiatan amal dan promosi kami dan berkontribusi untuk
                        kesehatan lautan dan planet kita.`,
        text3: 'Hubungi Kami',
        text4: 'Bagikan ke:',
        text5: `Visi kami adalah untuk meningkatkan kesehatan laut global, melindungi ekosistem laut, dan
                menjaga keanekaragaman hayati laut.`,
        text6: 'Berita Terkini',
        text7: `Bergabunglah dengan kami untuk Shark Week 2023 dan jelajahi dunia penelitian baru dan pengelolaan hiu berkelanjutan.`,
        text8: 'LEBIH LANJUT>>',

        p1: 'Beranda › Tentang Kami',
        p2: `Untuk berkembang secara global, yayasan Sphere X Fund, dengan motto "Mulai dari Perspektif Laut," telah memilih ide yang kuat dan mengubahnya menjadi tiga pilar utama aksi. Pilar-pilar ini akan secara kolektif mempromosikan pendidikan, kesadaran, partisipasi, dan perubahan perilaku. Mereka juga akan mengambil tindakan untuk menerapkan ekonomi biru yang inovatif serta perlindungan dan pemulihan laut. Dalam proses ini, dengan memperkuat demokratisasi dan memperluas skala, kami akan berupaya memanfaatkan laut secara berkelanjutan.`,
        p3: `Beranda › Aksi Amerika`,
        p4: `Aksi Penjaga Laut Warga Amerika`,
        p5: `Beranda › Daftar Spesies Laut yang Terancam Punah`,
        p6: `Daftar Spesies Laut yang Terancam Punah`,
        p7: `SPESIES LAUT TERANCAM PUNAH`,
        p8: `Selamat datang di SXF`,
        p9: `Anjing Laut Biarawan Hawaii`,
        p10: `Beranda › GABUNG DENGAN KAMI`,
        p11: `SXF (Nama Asosiasi)`,
        p12: `Sphere X Fund (SXF) adalah organisasi amal nirlaba yang berbasis di Amerika Serikat, didedikasikan untuk melindungi ekosistem laut dan melestarikan keanekaragaman kehidupan laut. SXF telah meninggalkan metode iklan tradisional dan mengembangkan strategi promosi eksklusif. Dengan memanfaatkan teknologi dan kekuatan komunitas, SXF menawarkan penghargaan nyata kepada para pendukung lingkungan, sehingga meningkatkan kesadaran dan aksi untuk konservasi laut.`,
        p13: `Beranda › EKOLOGI LAUT`,
        p14: `Ekologi laut merujuk pada hubungan timbal balik antara berbagai organisme laut dan interaksi mereka dengan lingkungan sekitarnya. Ekosistem laut meliputi komponen seperti air laut, topografi dasar laut, hamparan lamun, terumbu karang, dan plankton, membentuk ekosistem yang kompleks dan kaya. Ekologi laut memainkan peran penting dalam keseimbangan ekologi bumi dengan menyediakan makanan, oksigen, dan sumber daya, serta mempengaruhi iklim, sistem sirkulasi global, dan siklus karbon. Melindungi ekosistem laut sangat penting untuk mempertahankan keseimbangan ekologi bumi dan keberlanjutan lingkungan manusia. Saat ini, ekologi laut menghadapi berbagai tantangan dan masalah, yang terutama berkisar pada tema pemanasan global, penangkapan ikan berlebihan, dan polusi laut.`,

        navhome: "BERANDA",
        navabout: "TENTANG KAMI",
        navam: "AKSI AMERIKA",
        navjoin: "GABUNG DENGAN KAMI",
        navend: "SPESIES LAUT TERANCAM PUNAH",
        navmar: "EKOLOGI LAUT",
        User: 'Pusat Pengguna',

        home: 'BERANDA',
        Action: 'Dalam Aksi',
        Sharenow: "Bagikan sekarang",
        ReviewResult: 'Hasil Tinjauan',
        Inbox: 'Kotak Masuk',
        Account: 'Akun',
    },
    account: {
        t1: 'Akun Saya',
        t2: 'Kode Undangan:',
        t3: 'Pengundang:',
        t4: 'Tim Saya',
        t5: 'Judul Saya',
        t6: 'Kartu Saya',
        t7: 'Ubah Kata Sandi',
        t8: 'Bahasa',
        t9: 'Pengenalan Versi',
        t10: 'Hapus Akun',
        t11: 'Keluar',

        text: 'Detail Kartu',

        ai1: '› Ai',
        ai2: `Saat ini hanya transaksi USDT (Trc20) yang tersedia.
Setelah transfer, Anda harus kembali ke halaman sebelumnya (detail kartu) untuk mengunggah
tangkapan layar transaksi untuk menyelesaikan pembayaran!`,
        ai3: `Alamat pengiriman (salin alamat di bawah ini)`,

        c1: ` › Kartu Saya`,
        c2: 'Nomor: x',
        c3: 'Pembayaran: Cryptocurrency',
        c4: 'Tanggal:',
        c5: 'Sedang Ditinjau',
        c6: 'Disetujui',
        c7: 'Ditolak',
        c8: 'Nomor Kartu:',
        c9: 'Kode Aktivasi:',
        c10: 'Alasan Penolakan:',

        d1: '› Detail Kartu',
        d2: `Harga Asli:`,
        d3: `Diskon: `,
        d4: `Pembayaran Aktual`,
        d5: `Pembayaran Cryptocurrency`,
        d6: `Layanan Pelanggan`,
        d7: `Silakan klik layanan pelanggan untuk mendapatkan alamat penerima. Setelah pembayaran selesai, kembali ke sini untuk mengunggah tangkapan layar.`,
        d8: `Beli`,
        d9: 'Masukkan nilai hash',

        a1: `› Pembatalan Akun`,
        a2: `Pembatalan Akun`,
        a3: `Kata Sandi:`,
        a4: `Masukkan kata sandi`,
        a5: `Masukkan kata sandi akun saat ini untuk mengonfirmasi pembatalan akun. Setelah akun dibatalkan, Anda tidak akan dapat masuk lagi. Harap diperhatikan.`,
        a6: `Konfirmasi`,

        cp1: ` › Ubah Kata Sandi`,
        cp2: `Atur kata sandi baru`,
        cp3: `Kata Sandi Lama:`,
        cp4: `Masukkan kata sandi lama`,
        cp5: `Kata Sandi Baru:`,
        cp6: `Masukkan kata sandi baru`,
        cp7: `Konfirmasi Kata Sandi:`,
        cp8: 'Masukkan kata sandi untuk konfirmasi',
        cp9: 'Konfirmasi',

        l1: ` › Pengaturan Bahasa`,

        mt1: ` › Tim Saya`,
        mt2: `Tautan Undangan`,
        mt3: `Salin Tautan`,
        mt4: `Bagikan Harian`,
        mt5: `Undang Mitra`,
        mt6: `Nama Pengguna`,
        mt7: `Tingkat`,
        mt8: `Waktu`,

        tle1: ` › Judul Saya`,
        tle2: `Tautan Undangan`,
        tle3: `Diskon 9.2`,
        tle4: 'Bagikan Harian',
        tle5: 'Undang Mitra',
        tle6: `Total Poin`,
        tle7: 'Peran',
        tle8: `1. Pelindung - Perunggu, membutuhkan s1% poin dan menikmati diskon s2%.`,
        tle9: `2. Pelindung - Perak, membutuhkan s1% poin dan menikmati diskon s2%.`,
        tle10: `3. Pelindung - Emas, membutuhkan s1% poin dan menikmati diskon s2%.`,
        tle11: `4. Pelindung - Berlian, membutuhkan s1% poin dan menikmati diskon s2%.`,
        tle12: `5. Pelindung - Tertinggi, membutuhkan s1% atau lebih poin dan menikmati diskon s2%.`,
        tle13: `Cara mendapatkan poin`,
        tle14: `Setiap kali Anda membagikan konten terkait perlindungan laut dan mengunggah tangkapan layar ke Sphere X Fund, serta saat Anda membeli kartu hadiah Amazon, Anda akan mendapatkan 1 poin. Ketika poin Anda mencapai ambang tertentu, Anda akan mendapatkan diskon yang lebih besar. Bergabunglah dengan kami dan berkontribusilah untuk perlindungan laut!`,
        
        v1: ` › Pengenalan Versi`,
        v2: `Deskripsi Versi`,
        v3: `Nomor Versi`,
    },

    inaction: {
        t1: 'Langkah 1: Silakan simpan poster acara kami.',
        t2: 'Tekan dan tahan foto poster untuk menyimpannya ke album.',
        t3: `Langkah 2: Silakan bagikan foto ini di platform sosial kami untuk mengundang lebih
                        banyak orang untuk berpartisipasi dalam perlindungan ekologi laut`,
        t4: 'Bagikan ke:',
        t5: `Setiap kali Anda berbagi, itu menunjukkan kepedulian Anda terhadap ekologi laut. SXF
                        mengucapkan terima kasih atas kebaikan Anda!`,
        t6: `Langkah 3: Silakan kirimkan bukti tangkapan layar dari poster acara kami
                        yang telah Anda bagikan di platform sosial. Anda
                        dapat berpartisipasi dalam acara setelah peninjauan selesai. Terima kasih!`,
        t7: 'Silakan unggah foto',
        t8: 'Bagikan sekarang',
    },

    inactX: {
        rejected: 'Ditolak',
        under: 'Sedang Ditinjau',
        approved: 'Disetujui',
    },

    inbox: {
        t1: 'Beli Sekarang',

    },

    login: {
        Username: 'Nama Pengguna',
        LoginPassword: 'Kata Sandi Login',
        Registeraccount: 'Daftar Akun',
        Retrievepassword: 'Pulihkan Kata Sandi',
        login: 'Masuk',
        loginSS: 'Berhasil Masuk',
        t1: 'Konfirmasi Kata Sandi',
        t2: 'Masukkan nama lengkap',
        t3: 'Nomor telepon',
        t4: 'Email',
        t5: 'kode',
        t6: 'Kode Undangan',
        t7: 'Daftar',
        t8: 'Batal',
        t9: 'Halaman Pendaftaran',
        t10: 'Pulihkan Kata Sandi',
    },

    foo: {
        t1: 'Bergabunglah dengan komunitas global kami',
        t2: `Daftar ke daftar email kami dan hubungkan dengan kami di media sosial. Bersama-sama kita
                        menggerakkan dunia untuk melindungi hewan.`,
        t3: `Daftar ke daftar email kami`,
        t4: `Dapatkan berita dan blog terbaru melalui email.`,
        t5: `Daftar`,
        t6: `Unduh Aplikasi`,
        t7: `Pengalaman yang lebih baik, lebih banyak diskon`,
        t8: `Asosiasi Konservasi Laut Dunia, perusahaan terbatas yang berbasis di`,
        t9: `Amerika Serikat dengan nomor registrasi 20211058723`,
        t10: `4255 South Buckley Road #1296, Aurora, CO 80013, Amerika Serikat`,
    },

    More: 'Lebih Lanjut',
    onBuy: "Saat ini Anda belum memenuhi syarat untuk membeli, silakan bagikan dan kembali lagi nanti",
	onBuyed: "Anda telah membeli hari ini, silakan kembali besok",
    copyC: "Berhasil disalin",
	copyS: "Gagal menyalin",
};