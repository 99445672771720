// 泰语
export default {
    home: {
        text1: 'ยินดีต้อนรับสู่',
        SXF: 'SXF',
        text2: `นี่คือการดำเนินการเร่งด่วนเพื่อดูแลและปกป้องระบบนิเวศทางทะเล ที่นี่คุณจะได้เรียนรู้เกี่ยวกับ
                        การปกป้องสิ่งแวดล้อมทางทะเลและความหลากหลายทางชีวภาพทางทะเล นอกจากนี้คุณยังมี
                        โอกาสเข้าร่วมกิจกรรมการกุศลและการส่งเสริมการขายของเรา เพื่อร่วมเป็นส่วนหนึ่งในการ
                        ดูแลสุขภาพของมหาสมุทรและโลกของเรา`,
        text3: 'ติดต่อเรา',
        text4: 'แชร์ไปที่:',
        text5: `วิสัยทัศน์ของเราคือการปรับปรุงสุขภาพของมหาสมุทรทั่วโลก ปกป้องระบบนิเวศทางทะเล และ
                รักษาความหลากหลายทางชีวภาพทางทะเล`,
        text6: 'ข่าวล่าสุด',
        text7: `เข้าร่วมกับเราในสัปดาห์ฉลาม 2023 และสำรวจโลกของงานวิจัยใหม่และการจัดการฉลามอย่างยั่งยืน`,
        text8: 'เพิ่มเติม>>',

        p1: 'หน้าแรก › เกี่ยวกับเรา',
        p2: `เพื่อให้เกิดการพัฒนาในระดับสากล มูลนิธิ Sphere X Fund ได้ยึดมั่นในคำขวัญ "เริ่มจากมุมมองของมหาสมุทร" เลือกแนวคิดที่ทรงพลังและแปรเปลี่ยนเป็นสามเสาหลักแห่งการปฏิบัติ เสาหลักเหล่านี้จะร่วมกันส่งเสริมการศึกษา การสร้างความตระหนัก การมีส่วนร่วม และการเปลี่ยนแปลงพฤติกรรม พร้อมทั้งลงมือทำเพื่อสนับสนุนเศรษฐกิจสีน้ำเงินที่มีนวัตกรรมและการปกป้องฟื้นฟูมหาสมุทร ด้วยการขยายตัวและความเป็นประชาธิปไตย เราจะพยายามใช้ทรัพยากรของมหาสมุทรอย่างยั่งยืน`,
        p3: `หน้าแรก › กิจกรรมในอเมริกา`,
        p4: `การปกป้องมหาสมุทรของพลเมืองอเมริกัน`,
        p5: `หน้าแรก › รายชื่อสัตว์ทะเลที่ใกล้สูญพันธุ์`,
        p6: `รายชื่อสัตว์ทะเลที่ใกล้สูญพันธุ์`,
        p7: `สัตว์ทะเลที่ใกล้สูญพันธุ์`,
        p8: `ยินดีต้อนรับสู่ SXF`,
        p9: `แมวน้ำฮาวาย`,
        p10: `หน้าแรก › เข้าร่วมกับเรา`,
        p11: `SXF (ชื่อสมาคม)`,
        p12: `Sphere X Fund (SXF) เป็นองค์กรการกุศลที่ไม่แสวงหากำไร ตั้งอยู่ในสหรัฐอเมริกา โดยมุ่งมั่นในการปกป้องระบบนิเวศทางทะเลและรักษาความหลากหลายของสิ่งมีชีวิตทางทะเล SXF ได้ละทิ้งวิธีการโฆษณาแบบดั้งเดิมและพัฒนากลยุทธ์การโปรโมทของตนเอง โดยใช้เทคโนโลยีและพลังของชุมชน SXF มอบรางวัลที่จับต้องได้ให้แก่ผู้สนับสนุนสิ่งแวดล้อม เพิ่มการตระหนักรู้และการดำเนินการเพื่อการอนุรักษ์ทางทะเล`,
        p13: `หน้าแรก › นิเวศวิทยาทางทะเล`,
        p14: `นิเวศวิทยาทางทะเลหมายถึงความสัมพันธ์ระหว่างสิ่งมีชีวิตทางทะเลต่าง ๆ และปฏิสัมพันธ์ของพวกมันกับสิ่งแวดล้อมรอบข้าง ระบบนิเวศทางทะเลประกอบด้วยองค์ประกอบต่าง ๆ เช่น น้ำทะเล ภูมิประเทศใต้น้ำ ทุ่งหญ้าทะเล แนวปะการัง และแพลงก์ตอน ซึ่งรวมกันเป็นระบบนิเวศที่ซับซ้อนและหลากหลาย นิเวศวิทยาทางทะเลมีบทบาทสำคัญในการสร้างสมดุลของโลกโดยให้แหล่งอาหาร ออกซิเจน ทรัพยากร และยังมีอิทธิพลต่อสภาพภูมิอากาศ ระบบการหมุนเวียนของโลก และวงจรคาร์บอน การปกป้องระบบนิเวศทางทะเลมีความจำเป็นในการรักษาสมดุลทางนิเวศวิทยาของโลกและความยั่งยืนของสิ่งแวดล้อมของมนุษย์ ขณะนี้ นิเวศวิทยาทางทะเลกำลังเผชิญกับความท้าทายและปัญหาต่าง ๆ โดยเฉพาะเรื่องภาวะโลกร้อน การจับปลาเกินขนาด และมลภาวะทางทะเล`,

        navhome: "หน้าแรก",
        navabout: "เกี่ยวกับเรา",
        navam: "กิจกรรมในอเมริกา",
        navjoin: "เข้าร่วมกับเรา",
        navend: "สัตว์ทะเลที่ใกล้สูญพันธุ์",
        navmar: "นิเวศวิทยาทางทะเล",
        User: 'ศูนย์ผู้ใช้',

        home: 'หน้าแรก',
        Action: 'อยู่ในกิจกรรม',
        Sharenow: "แชร์ตอนนี้",
        ReviewResult: 'ผลการตรวจสอบ',
        Inbox: 'กล่องจดหมาย',
        Account: 'บัญชี',
    },
    account: {
        t1: 'บัญชีของฉัน',
        t2: 'รหัสคำเชิญ:',
        t3: 'ผู้เชิญ:',
        t4: 'ทีมของฉัน',
        t5: 'ตำแหน่งของฉัน',
        t6: 'บัตรของฉัน',
        t7: 'เปลี่ยนรหัสผ่าน',
        t8: 'ภาษา',
        t9: 'แนะนำเวอร์ชัน',
        t10: 'ลบบัญชี',
        t11: 'ออกจากระบบ',

        text: 'รายละเอียดบัตร',

        ai1: '› Ai',
        ai2: `ปัจจุบันสามารถทำธุรกรรมได้เฉพาะ USDT (Trc20)
หลังจากการโอนเงิน คุณต้องกลับไปที่หน้าก่อนหน้า (รายละเอียดบัตร) เพื่ออัปโหลด
ภาพหน้าจอธุรกรรมเพื่อทำการชำระเงินให้เสร็จสิ้น!`,
        ai3: `ที่อยู่จัดส่ง (คัดลอกที่อยู่ด้านล่าง)`,

        c1: ` › บัตรของฉัน`,
        c2: 'หมายเลข: x',
        c3: 'การชำระเงิน: สกุลเงินดิจิทัล',
        c4: 'วันที่:',
        c5: 'อยู่ระหว่างการตรวจสอบ',
        c6: 'ผ่านการอนุมัติ',
        c7: 'ถูกปฏิเสธ',
        c8: 'หมายเลขบัตร:',
        c9: 'รหัสเปิดใช้งาน:',
        c10: 'เหตุผลที่ปฏิเสธ:',

        d1: '› รายละเอียดบัตร',
        d2: `ราคาต้นฉบับ:`,
        d3: `ส่วนลด: `,
        d4: `การชำระเงินจริง`,
        d5: `การชำระเงินด้วยสกุลเงินดิจิทัล`,
        d6: `ฝ่ายบริการลูกค้า`,
        d7: `กรุณาคลิกฝ่ายบริการลูกค้าเพื่อรับที่อยู่สำหรับจัดส่ง เมื่อชำระเงินเสร็จแล้ว กรุณากลับมาที่นี่เพื่ออัปโหลดภาพหน้าจอ`,
        d8: `ซื้อ`,
        d9: 'ใส่ค่าแฮช',

        a1: `› ยกเลิกบัญชี`,
        a2: `ยกเลิกบัญชี`,
        a3: `รหัสผ่าน:`,
        a4: `กรุณาใส่รหัสผ่าน`,
        a5: `กรุณาใส่รหัสผ่านปัจจุบันของบัญชีเพื่อยืนยันการยกเลิกบัญชี เมื่อบัญชีถูกยกเลิกแล้ว คุณจะไม่สามารถเข้าสู่ระบบได้อีก กรุณาทราบ`,
        a6: `ยืนยัน`,

        cp1: ` › เปลี่ยนรหัสผ่าน`,
        cp2: `ตั้งรหัสผ่านใหม่`,
        cp3: `รหัสผ่านเก่า:`,
        cp4: `กรุณาใส่รหัสผ่านเก่า`,
        cp5: `รหัสผ่านใหม่:`,
        cp6: `กรุณาใส่รหัสผ่านใหม่`,
        cp7: `ยืนยันรหัสผ่าน:`,
        cp8: 'กรุณาใส่รหัสผ่านเพื่อยืนยัน',
        cp9: 'ยืนยัน',

        l1: ` › ตั้งค่าภาษา`,

        mt1: ` › ทีมของฉัน`,
        mt2: `ลิงก์เชิญ`,
        mt3: `คัดลอกลิงก์`,
        mt4: `แชร์รายวัน`,
        mt5: `เชิญพันธมิตร`,
        mt6: `ชื่อผู้ใช้`,
        mt7: `ระดับ`,
        mt8: `เวลา`,

        tle1: ` › ตำแหน่งของฉัน`,
        tle2: `ลิงก์เชิญ`,
        tle3: `ส่วนลด 9.2`,
        tle4: 'แชร์รายวัน',
        tle5: 'เชิญพันธมิตร',
        tle6: `คะแนนรวม`,
        tle7: 'บทบาท',
        tle8: `1. ผู้พิทักษ์ - บรอนซ์ ต้องการ s1% คะแนน และได้รับส่วนลด s2%`,
        tle9: `2. ผู้พิทักษ์ - ซิลเวอร์ ต้องการ s1% คะแนน และได้รับส่วนลด s2%`,
        tle10: `3. ผู้พิทักษ์ - โกลด์ ต้องการ s1% คะแนน และได้รับส่วนลด s2%`,
        tle11: `4. ผู้พิทักษ์ - ไดมอนด์ ต้องการ s1% คะแนน และได้รับส่วนลด s2%`,
        tle12: `5. ผู้พิทักษ์ - สุพรีม ต้องการ s1% หรือมากกว่าของคะแนน และได้รับส่วนลด s2%`,
        tle13: `วิธีการรับคะแนน`,
        tle14: `ทุกครั้งที่คุณแชร์เนื้อหาที่เกี่ยวข้องกับการอนุรักษ์มหาสมุทรและอัปโหลดภาพหน้าจอไปยัง Sphere X Fund และเมื่อคุณซื้อบัตรของขวัญ Amazon คุณจะได้รับ 1 คะแนน เมื่อคะแนนของคุณถึงเกณฑ์ที่กำหนด คุณจะได้รับส่วนลดที่มากขึ้น มาร่วมกับเราและมีส่วนร่วมในการอนุรักษ์มหาสมุทร!`,
        
        v1: ` › แนะนำเวอร์ชัน`,
        v2: `รายละเอียดเวอร์ชัน`,
        v3: `หมายเลขเวอร์ชัน`,
    },

    inaction: {
        t1: 'ขั้นตอนที่ 1: กรุณาบันทึกโปสเตอร์กิจกรรมของเรา',
        t2: 'กดค้างที่รูปโปสเตอร์เพื่อบันทึกลงในอัลบั้ม',
        t3: `ขั้นตอนที่ 2: กรุณาแชร์รูปนี้บนแพลตฟอร์มโซเชียลของเราเพื่อเชิญชวนให้
                        ผู้คนมาร่วมอนุรักษ์ระบบนิเวศทางทะเล`,
        t4: 'แชร์ไปที่:',
        t5: `ทุกครั้งที่คุณแชร์เป็นการแสดงออกถึงความห่วงใยต่อระบบนิเวศทางทะเล SXF
                        ขอขอบคุณสำหรับการกระทำดีของคุณ!`,
        t6: `ขั้นตอนที่ 3: กรุณาส่งหลักฐานภาพหน้าจอของโปสเตอร์กิจกรรมที่แชร์บนแพลตฟอร์มโซเชียลของเรา
                        คุณสามารถเข้าร่วมกิจกรรมได้หลังจากการตรวจสอบเสร็จสิ้น ขอบคุณ!`,
        t7: 'กรุณาอัปโหลดรูปภาพ',
        t8: 'แชร์ตอนนี้',
    },

    inactX: {
        rejected: 'ถูกปฏิเสธ',
        under: 'อยู่ระหว่างตรวจสอบ',
        approved: 'อนุมัติแล้ว',
    },

    inbox: {
        t1: 'ซื้อเลย',

    },

    login: {
        Username: 'ชื่อผู้ใช้',
        LoginPassword: 'รหัสผ่านเข้าสู่ระบบ',
        Registeraccount: 'ลงทะเบียนบัญชี',
        Retrievepassword: 'กู้คืนรหัสผ่าน',
        login: 'เข้าสู่ระบบ',
        loginSS: 'เข้าสู่ระบบสำเร็จ',
        t1: 'ยืนยันรหัสผ่าน',
        t2: 'กรุณาใส่ชื่อเต็ม',
        t3: 'หมายเลขโทรศัพท์',
        t4: 'อีเมล',
        t5: 'รหัส',
        t6: 'รหัสคำเชิญ',
        t7: 'ลงทะเบียน',
        t8: 'ยกเลิก',
        t9: 'หน้าลงทะเบียน',
        t10: 'กู้คืนรหัสผ่าน',
    },

    foo: {
        t1: 'เข้าร่วมกับชุมชนระดับโลกของเรา',
        t2: `สมัครเข้าร่วมรายชื่อผู้รับจดหมายของเราและเชื่อมต่อกับเราบนโซเชียลมีเดีย ร่วมกันเรากำลัง
                        ขับเคลื่อนโลกเพื่อปกป้องสัตว์`,
        t3: `สมัครเข้าร่วมรายชื่อผู้รับจดหมายของเรา`,
        t4: `รับข่าวสารล่าสุดและบล็อกผ่านอีเมล`,
        t5: `สมัคร`,
        t6: `ดาวน์โหลดแอป`,
        t7: `ประสบการณ์ที่ดียิ่งขึ้น ส่วนลดมากมาย`,
        t8: `สมาคมอนุรักษ์มหาสมุทรโลก บริษัทจำกัดที่ตั้งอยู่ใน`,
        t9: `สหรัฐอเมริกาพร้อมหมายเลขจดทะเบียน 20211058723`,
        t10: `4255 South Buckley Road #1296, Aurora, CO 80013, สหรัฐอเมริกา`,
    },

    More: 'เพิ่มเติม',
    onBuy: "ปัจจุบันคุณไม่ผ่านเงื่อนไขการซื้อ กรุณาแชร์และกลับมาใหม่",
	onBuyed: "วันนี้คุณได้ซื้อแล้ว กรุณากลับมาพรุ่งนี้",
    copyC: "คัดลอกสำเร็จ",
	copyS: "คัดลอกล้มเหลว",
};