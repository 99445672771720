// 法文
export default {
    home: {
        text1: 'BIENVENUE À',
        SXF: 'SXF',
        text2: `Ceci est une action urgente pour prendre soin et protéger l'écologie marine. Ici, vous apprendrez
                        à protéger l'environnement écologique marin et la biodiversité marine. En outre, vous aurez
                        l'opportunité de participer à nos activités caritatives et promotionnelles et de faire notre part pour
                        la santé de nos océans et de notre planète.`,
        text3: 'Contactez-nous',
        text4: 'Partager sur :',
        text5: `Notre vision est d'améliorer la santé des océans dans le monde, de protéger les écosystèmes marins et
                de préserver la biodiversité marine.`,
        text6: 'Dernières nouvelles',
        text7: `Rejoignez-nous pour la Semaine du Requin 2023 et plongez dans le monde de la recherche et de la gestion durable
                                des requins.`,
        text8: 'PLUS>>',

        p1: 'Accueil › À propos de nous',
        p2: `Afin de se développer à l'échelle mondiale, la fondation Sphere X Fund, guidée par sa devise "Partir
                    du point de vue de l'océan", a choisi une idée puissante et l'a transformée en trois piliers
                    d'action principaux. Ces piliers visent à promouvoir l'éducation, la sensibilisation, la participation et
                    le changement de comportement. Ils visent également à mettre en œuvre des économies bleues innovantes ainsi que la
                    protection et la restauration des océans. Dans ce processus, en renforçant la démocratisation
                    et en augmentant l'échelle, nous viserons à utiliser les océans de manière durable.`,
        p3: `Accueil › Action américaine`,
        p4: `Action des Gardiens de l'Océan pour les citoyens américains`,
        p5: `Accueil › Liste des espèces marines en danger`,
        p6: `Liste des espèces marines en danger`,
        p7: `ESPÈCES MARINES EN DANGER`,
        p8: `Bienvenue chez SXF`,
        p9: `Phoque moine d'Hawaï`,
        p10: `Accueil › REJOIGNEZ-NOUS`,
        p11: `SXF (Nom de l'association)`,
        p12: `Sphere X Fund (SXF) est une organisation de bienfaisance à but non lucratif basée aux États-Unis, dédiée à la protection des écosystèmes marins et à la préservation de la diversité de la vie marine. SXF a abandonné les méthodes publicitaires traditionnelles et a développé ses propres stratégies de promotion. En tirant parti de la technologie et de la puissance de la communauté, elle offre des récompenses concrètes aux défenseurs de l'environnement, augmentant ainsi la sensibilisation et l'action en faveur de la conservation marine.`,
        p13: `Accueil › ÉCOLOGIE MARINE`,
        p14: `L'écologie marine désigne les interrelations collectives entre divers organismes marins et leurs interactions avec l'environnement environnant. Les écosystèmes marins comprennent des éléments tels que l'eau de mer, la topographie des fonds marins, les herbiers marins, les récifs coralliens et le plancton, formant un écosystème complexe et riche. L'écologie marine joue un rôle crucial dans l'équilibre écologique de la Terre en fournissant de la nourriture, de l'oxygène et des ressources, et en influençant le climat, les systèmes de circulation mondiaux et le cycle du carbone. La protection des écosystèmes marins est essentielle pour maintenir l'équilibre écologique de la Terre et la durabilité de l'environnement humain. Actuellement, l'écologie marine fait face à une série de défis et de problèmes, principalement autour des thèmes du réchauffement climatique, de la surpêche et de la pollution marine.`,

        navhome: "ACCUEIL",
        navabout: "À PROPOS DE NOUS",
        navam: "ACTION AMÉRICAINE",
        navjoin: "REJOIGNEZ-NOUS",
        navend: "ESPÈCES MARINES EN DANGER",
        navmar: "ÉCOLOGIE MARINE",
        User: 'Centre utilisateur',

        home: 'ACCUEIL',
        Action: 'En action',
        Sharenow: "Partager maintenant",
        ReviewResult: 'Résultat de l\'examen',
        Inbox: 'Boîte de réception',
        Account: 'Compte',
    },
    account: {
        t1: 'Mon compte',
        t2: 'Code d\'invitation :',
        t3: 'Parrain :',
        t4: 'Mon équipe',
        t5: 'Mon titre',
        t6: 'Ma carte',
        t7: 'Changer le mot de passe',
        t8: 'Langue',
        t9: 'Introduction de la version',
        t10: 'Supprimer le compte',
        t11: 'Déconnexion',

        text: 'Détails de la carte',

        ai1: '› Ai',
        ai2: `Actuellement, seules les transactions USDT (Trc20) sont disponibles.
Après le transfert, vous devez revenir à la page précédente (détails de la carte) pour télécharger une capture d'écran de la transaction afin de
compléter le paiement !`,
        ai3: `Adresse de livraison (copiez l'adresse ci-dessous)`,

        c1: ` › Ma carte`,
        c2: 'Numéro : x',
        c3: 'Paiement : Cryptomonnaie',
        c4: 'Date :',
        c5: 'En cours de révision',
        c6: 'Accepté',
        c7: 'Rejeté',
        c8: 'Numéro de carte :',
        c9: 'Code d’activation :',
        c10: 'Raison du refus :',

        d1: '› Détails de la carte',
        d2: `Prix original :`,
        d3: `Remise :`,
        d4: `Paiement réel`,
        d5: `Paiements en cryptomonnaie`,
        d6: `Service client Al`,
        d7: `Veuillez cliquer sur le service client Al pour obtenir l'adresse de collecte. Une fois le paiement terminé, revenez ici pour télécharger une capture d'écran.`,
        d8: `Acheter`,
        d9: 'Entrez la valeur de hachage',

        a1: `› Annulation de compte`,
        a2: `Annulation de compte`,
        a3: `Mot de passe :`,
        a4: `Veuillez entrer le mot de passe`,
        a5: `Veuillez entrer le mot de passe actuel du compte pour confirmer l'annulation du compte. Une fois le compte annulé, vous ne pourrez plus vous connecter. Veuillez noter`,
        a6: `Confirmer`,

        cp1: `› Changer de mot de passe`,
        cp2: `Définir un nouveau mot de passe`,
        cp3: `Ancien mot de passe :`,
        cp4: `Veuillez entrer l'ancien mot de passe`,
        cp5: `Nouveau mot de passe :`,
        cp6: `Veuillez entrer un nouveau mot de passe`,
        cp7: `Confirmer le mot de passe :`,
        cp8: 'Veuillez entrer le mot de passe pour confirmer',
        cp9: 'Confirmer',

        l1: `› Paramètres de langue`,

        mt1: `› Mon équipe`,
        mt2: `Lien d'invitation`,
        mt3: `Copier le lien`,
        mt4: `Partager quotidiennement`,
        mt5: `Inviter des partenaires`,
        mt6: `Nom d'utilisateur`,
        mt7: `Niveau`,
        mt8: `Temps`,

        tle1: `› Mon titre`,
        tle2: `Lien d'invitation`,
        tle3: `9,2 Remise`,
        tle4: 'Partager quotidiennement',
        tle5: 'Inviter des partenaires',
        tle6: `Total des points`,
        tle7: 'Rôle',
        tle8: `1. Gardien - Bronze, nécessite s1% de points et bénéficie d'une réduction de s2%.`,
        tle9: `2. Gardien - Argent, nécessite s1% de points et bénéficie d'une réduction de s2%.`,
        tle10: `3. Gardien - Or, nécessite s1% de points et bénéficie d'une réduction de s2%.`,
        tle11: `4. Gardien - Diamant, nécessite s1% de points et bénéficie d'une réduction de s2%.`,
        tle12: `5. Gardien - Suprême, nécessite s1% ou plus de points et bénéficie d'une réduction de s2%.`,
        tle13: `Comment gagner des points`,
        tle14: `Chaque fois que vous partagez du contenu lié à la protection des océans et téléchargez des captures d'écran sur Sphere X Fund, ainsi que lorsque vous achetez des cartes-cadeaux Amazon, vous gagnez 1 point. Lorsque vos points atteignent un certain seuil, vous bénéficiez de réductions plus importantes. Rejoignez-nous et contribuez à la protection des océans !`,
        
        v1: `› Introduction de la version`,
        v2: `Description de la version`,
        v3: `Numéro de version`,
    },

    inaction: {
        t1: 'Étape 1 : Veuillez enregistrer notre affiche événementielle.',
        t2: 'Appuyez et maintenez la photo de l\'affiche pour l\'enregistrer dans l\'album.',
        t3: `Étape 2 : Veuillez partager cette photo sur nos plateformes sociales pour inviter plus
                        de personnes à participer à la protection de l'écologie marine`,
        t4: 'Partager sur :',
        t5: `Chaque partage que vous faites témoigne de votre préoccupation pour l'écologie marine. SXF
                        vous remercie pour vos bonnes actions !`,
        t6: `Étape 3 : Veuillez soumettre la preuve des captures d'écran de nos affiches d'événements
                        partagées sur les plateformes sociales. Vous
                        pouvez participer à l'événement après la révision. Merci !`,
        t7: 'Veuillez télécharger les photos',
        t8: 'Partager maintenant',
    },

    inactX: {
        rejected: 'Rejeté',
        under: 'En cours de révision',
        approved: 'Approuvé',
    },

    inbox: {
        t1: 'Acheter maintenant',

    },

    login: {
        Username: 'Nom d\'utilisateur',
        LoginPassword: 'Mot de passe de connexion',
        Registeraccount: 'Créer un compte',
        Retrievepassword: 'Récupérer le mot de passe',
        login: 'Connexion',
        loginSS: 'Connexion réussie',
        t1: 'Confirmer le mot de passe',
        t2: 'Veuillez entrer le nom complet',
        t3: 'Numéro de téléphone',
        t4: 'E-mail',
        t5: 'code',
        t6: 'Code d\'invitation',
        t7: 'S\'inscrire',
        t8: 'Annuler',
        t9: 'Page d\'inscription',
        t10: 'Récupérer le mot de passe',
    },

    foo: {
        t1: 'Rejoignez notre communauté mondiale',
        t2: `Inscrivez-vous à notre liste de diffusion et connectez-vous avec nous sur les réseaux sociaux. Ensemble, nous
                        faisons avancer le monde pour protéger les animaux.`,
        t3: `Inscrivez-vous à notre liste de diffusion`,
        t4: `Recevez les dernières nouvelles et blogs par e-mail.`,
        t5: `S'inscrire`,
        t6: `Télécharger l'application`,
        t7: `Meilleure expérience, plus de réductions`,
        t8: `Association mondiale pour la conservation des océans, une société à responsabilité limitée basée aux`,
        t9: `États-Unis avec le numéro d'enregistrement 20211058723`,
        t10: `4255 South Buckley Road #1296, Aurora, CO 80013, États-Unis`,
    },

    More: 'Plus',
    onBuy: "Vous ne remplissez actuellement pas les conditions d'achat, veuillez partager et revenir plus tard",
	onBuyed: "Vous avez déjà acheté aujourd'hui, veuillez revenir demain",
    copyC: "Copié avec succès",
	copyS: "Échec de la copie",
};