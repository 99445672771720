<template>
    <header :class="!login ? 'header' : 'header Nadd'">
        <nav class="pc flex" v-if="!login">
            <div class="header_logo" @click="toPages('/')">
                <img src="@/assets/logo.png" alt="Logo" />
            </div>
            <ul class="header_nav">
                <li :class="nav == '/' ? 'add' : ''" @click="toPages('/')">{{ $t('home.navhome') }}</li>
                <li :class="nav == '/about' ? 'add' : ''" @click="toPages('/about')">{{ $t('home.navabout') }}</li>
                <li :class="nav == '/american' ? 'add' : ''" @click="toPages('/american')">{{ $t('home.navam') }}</li>
                <li :class="nav == '/join' ? 'add' : ''" @click="toPages('/join')">{{ $t('home.navjoin') }}</li>
                <li :class="nav == '/end' ? 'add' : ''" @click="toPages('/end')">{{ $t('home.navend') }}</li>
                <li :class="nav == '/mar' ? 'add' : ''" @click="toPages('/mar')">{{ $t('home.navmar') }}</li>
            </ul>
            <div class="header_btn">
                <div class="header_btn_selct flex" @click="toPages('/lang?type=' + type)">
                    <img :src="require(`@/assets/lang/${langUrl || 'English'}.png`)" alt="">
                    <span>{{ lang || 'English' }}</span>
                    <img class="i" src="@/assets/home/xia.png" alt="">
                </div>

                <div class="header_btn_user flex_c" @click="toUser('/account')">{{ $t('home.User') }}</div>
            </div>
        </nav>

        <div class="login_lang" v-else>
            <div class="header_btn_selct flex" @click="toPages('/lang?type=' + type)">
                <img :src="require(`@/assets/lang/${langUrl || 'English'}.png`)" alt="">
                <span>{{ lang || 'English' }}</span>
                <img class="i" src="@/assets/home/xia.png" alt="">
            </div>
        </div>
    </header>
</template>

<script>
import router from '@/router';
export default {
    name: 'Home',
    data() {
        return {
            lang: 'English',
            langUrl: 'English',
        };
    },
    props: {
        nav: {
            type: String,
            default: '/'
        },
        type: {
            type: String,
            default: 'home'
        },
        login: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.lang = localStorage.getItem('langName')
        this.langUrl = localStorage.getItem('langUrl');
    },
    methods: {
        toPages(v) {
            if (this.nav == v) return
            router.push(v)
        },
        toUser(v) {
            router.push(v)
        }
    }
}
</script>


<style lang="less" scoped>
.Nadd{
    background: transparent !important;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0) !important;
}
.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 58px;
    background: rgba(0, 0, 0, 0.12);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.15);
    color: #fff;
    z-index:99;
    .pc {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .header_logo {
        cursor: pointer;

        img {
            width: 105px;
            height: 41px;
        }

        margin-right: 40px;
    }

    .header_nav {
        font-size: 14px;
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;

        li {
            padding: 0 20px;
            font-weight: bold;
            cursor: pointer;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        li:hover {
            background: #9137C1;
        }

        .add {
            background: #9137C1;
        }
    }

    .login_lang {
        position: absolute;
        top: 20%;
        right: 25%;

        .header_btn_selct {
            cursor: pointer;
            border: 1px solid #fff;
            border-radius: 22.25px;
            height: 44.5px;
            align-items: center;
            padding: 0 12px;
            box-sizing: border-box;

            img {
                width: 31px;
                height: 23px;
            }

            span {
                padding: 0 10px;
            }

            .i {
                width: 13.1px;
                height: 15.6px;
            }
        }
    }

    .header_btn {
        margin-left: 10px;
        display: flex;

        .header_btn_selct {
            cursor: pointer;
            border: 1px solid #fff;
            border-radius: 22.25px;
            height: 44.5px;
            align-items: center;
            padding: 0 12px;
            box-sizing: border-box;

            img {
                width: 31px;
                height: 23px;
            }

            span {
                padding: 0 10px;
            }

            .i {
                width: 13.1px;
                height: 15.6px;
            }
        }

        .header_btn_user {
            cursor: pointer;
            background: #F5AE18;
            border: 1px solid #fff;
            box-sizing: border-box;
            border-radius: 22.25px;
            padding: 0 25px;
            margin-left: 10px;
        }
    }

}
</style>