<template>
    <header class="header">
        <nav class="pc_h flex">
            <div class="header_logo">
                <img src="@/assets/account/logo.png" alt="Logo" />
            </div>
            <ul class="header_nav">
                <li :class="nav == '/' ? 'add' : ''"  @click="toPages('/')">
                    <img width="11px" height="12px" src="@/assets/account/home.png" alt="">
                    <div>{{ $t('home.home') }}</div>
                </li>
                <li :class="nav == '/Inaction' || nav == '/InactX' ? 'add Action' : 'Action'" >
                    <img width="12px" height="15px" src="@/assets/account/In Action.png" alt="">
                    <div>{{ $t('home.Action' )}}</div>
                    <div class="Action_box">
                        <div :class="nav == '/Inaction' ? 'add' : ''"  @click="toPages('/Inaction')">{{ $t('home.Sharenow')}}</div>
                        <div :class="nav == '/InactX' ? 'add' : ''"  @click="toPages('/InactX')">{{ $t('home.ReviewResult' )}}</div>
                    </div>
                </li>
                <li :class="nav == '/Inbox' ? 'add' : ''"  @click="toPages('/Inbox')">
                    <img width="15px" height="11px" src="@/assets/account/Inbox.png" alt="">
                    <div>{{ $t('home.Inbox' )}}</div>
                </li>
                <li :class="nav == '/account' ? 'add' : ''"  @click="toPages('/account')">
                    <img width="13px" height="13px" src="@/assets/account/Account.png" alt="">
                    <div>{{ $t('home.Account' )}}</div>
                </li>
            </ul>
        </nav>
    </header>
</template>

<script>
import router from '@/router';
export default {
    name: 'Home',
    data() {
        return {
        };
    },
    props: {
        nav: {
            type: String,
            default: '/'
        }
    },
    methods: {
        toPages(v) {
            if( this.nav == v ) return
            router.push(v)
        },
        toUser(v){
            router.push(v)
        }
    }
}
</script>


<style lang="less" scoped>
.header{
    position: fixed;
    top: 0;
    width: 100%;
    height: 58px;
    background: rgba(0,0,0,0.12);
    box-shadow: 0px 1px 0px 0px rgba(255,255,255,0.15);
    color: #fff;
    z-index:99;
    .pc_h{
        display: flex;
        align-items: center;
        height: 100%;
    }
    .header_logo{
        cursor: pointer;
        img{
            width: 105px;
            height: 41px;
        }
        margin-right: 140px;
    }
    .header_nav{
        font-size: 14px;
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
        li{
            padding: 0 30px;
            font-weight: bold;
            cursor: pointer;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            div{
                margin-left: 8px;   
            }
        }
        .Action{
            position: relative;
        }
        .Action:hover .Action_box{
            display: block;
        }
        .Action_box{
            position: absolute;
            bottom: -104px;
            left: 0;
            z-index: 2;
            
            background: #9137C1;
            display: none;
            // width: 120.48px;
            margin-left: 0;
            width: 100%;
            div{
                margin-left: 0;
                padding: 20px;
                font-size: 12px;
            }
            div:hover{
                background: #112477;
            }
            .add{
                background: #112477 !important;
            }
        }

        li:hover{
            background: #9137C1;
        }
        .add{
            background: #9137C1;
        }
    }
    .header_btn{
        margin-left: 10px;
        display: flex;
        .header_btn_selct{
            cursor: pointer;
            border: 1px solid #fff;
            border-radius: 22.25px;
            height: 44.5px;
            align-items: center;
            padding: 0 12px;
            box-sizing: border-box;
            img{
                width: 31px;
                height: 23px;
            }
            span{
                padding: 0 10px;
            }
            .i{
                width: 13.1px;
                height: 15.6px;
            }
        }
        .header_btn_user{
            cursor: pointer;
            background: #F5AE18;
            border: 1px solid #fff;
            box-sizing: border-box;
            border-radius: 22.25px;
            padding: 0 25px;
            margin-left: 10px;
        }
    }
    
}
</style>