<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
    </div>
</template>

<script>

export default {
    name: 'App',
    watch: {
        $route() {
            window.scrollTo(0, 0); // 每次路由变化时滚动到顶部
        },
    },
    created(){
        window.addEventListener('resize', this.setVH());
    },
    methods: {
        setVH() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    }
}
</script>

<style lang="less">
@import "./assets/css/style.less";
</style>
