// 韩语
export default {
    home: {
        text1: '환영합니다',
        SXF: 'SXF',
        text2: `해양 생태계를 보호하기 위한 긴급 행동입니다. 여기에서 해양 생태 환경과 해양 생물 다양성을 보호하는 방법에 대해 배울 수 있습니다. 또한 우리의 자선 및 홍보 활동에 참여하여 해양과 지구의 건강을 위해 기여할 기회를 가질 수 있습니다.`,
        text3: '문의하기',
        text4: '공유하기:',
        text5: `우리의 비전은 전 세계 해양 건강을 개선하고, 해양 생태계를 보호하며
                해양 생물 다양성을 유지하는 것입니다.`,
        text6: '최신 뉴스',
        text7: `2023 상어 주간에 참여하여 새로운 연구와 지속 가능한 상어 관리의 세계로 뛰어드세요.`,
        text8: '더 보기>>',

        p1: '홈 › 회사 소개',
        p2: `전 세계적으로 발전하기 위해, Sphere X Fund 재단은 "해양의 관점에서 시작"이라는 모토에 따라 강력한 아이디어를 선택하여 세 가지 주요 행동 기둥으로 전환했습니다. 이 기둥들은 집합적으로 교육, 인식, 참여 및 행동 변화를 촉진합니다. 또한 혁신적인 블루 경제를 구현하고 해양 보호와 복원 활동을 추진합니다. 이 과정에서 민주화를 강화하고 규모를 확대하여, 해양의 지속 가능한 활용을 위해 노력할 것입니다.`,
        p3: `홈 › 미국 활동`,
        p4: `전 미국 시민의 해양 수호 행동`,
        p5: `홈 › 멸종 위기 해양 종 목록`,
        p6: `멸종 위기 해양 종 목록`,
        p7: `멸종 위기 해양 종`,
        p8: `SXF에 오신 것을 환영합니다`,
        p9: `하와이 몽크 바다표범`,
        p10: `홈 › 참여하기`,
        p11: `SXF (협회 이름)`,
        p12: `Sphere X Fund (SXF)는 미국에 본부를 두고 해양 생태계 보호와 해양 생물 다양성 보존을 목표로 하는 비영리 자선 신탁 기관입니다. SXF는 전통적인 광고 방법을 포기하고 고유한 홍보 전략을 개발했습니다. 기술과 커뮤니티의 힘을 활용하여 환경 보호 활동가들에게 실질적인 보상을 제공함으로써 해양 보호에 대한 인식과 행동을 강화하고 있습니다.`,
        p13: `홈 › 해양 생태`,
        p14: `해양 생태는 다양한 해양 생물 간의 상호 관계와 그들이 주변 환경과 상호 작용하는 방식을 나타냅니다. 해양 생태계는 해수, 해저 지형, 해초 지대, 산호초 및 플랑크톤과 같은 요소들로 구성되어 복잡하고 풍부한 생태계를 형성합니다. 해양 생태는 음식, 산소, 자원을 제공하고 기후, 지구 순환 시스템 및 탄소 순환에 영향을 미침으로써 지구의 생태적 균형에서 중요한 역할을 합니다. 해양 생태계를 보호하는 것은 지구의 생태 균형을 유지하고 인류 환경의 지속 가능성을 보장하는 데 필수적입니다. 현재 해양 생태는 지구 온난화, 과도한 어획, 해양 오염 등의 주제로 여러 도전과 문제에 직면하고 있습니다.`,

        navhome: "홈",
        navabout: "회사 소개",
        navam: "미국 활동",
        navjoin: "참여하기",
        navend: "멸종 위기 해양 종",
        navmar: "해양 생태",
        User: '사용자 센터',

        home: '홈',
        Action: '활동 중',
        Sharenow: "지금 공유하기",
        ReviewResult: '리뷰 결과',
        Inbox: '받은 편지함',
        Account: '계정',
    },
    account: {
        t1: '내 계정',
        t2: '초대 코드:',
        t3: '초대자:',
        t4: '내 팀',
        t5: '내 칭호',
        t6: '내 카드',
        t7: '비밀번호 변경',
        t8: '언어',
        t9: '버전 소개',
        t10: '계정 삭제',
        t11: '로그아웃',

        text: '카드 세부 사항',

        ai1: '› Ai',
        ai2: `현재 USDT (Trc20) 거래만 가능합니다.
송금 후 이전 페이지 (카드 세부 정보)로 돌아가서 거래 스크린샷을 업로드하여
결제를 완료하세요!`,
        ai3: `배송 주소 (아래 주소 복사)`,

        c1: ` › 내 카드`,
        c2: '번호: x',
        c3: '결제: 암호화폐',
        c4: '날짜:',
        c5: '검토 중',
        c6: '승인됨',
        c7: '거부됨',
        c8: '카드 번호:',
        c9: '활성화 코드:',
        c10: '거절 사유:',

        d1: '› 카드 세부 사항',
        d2: `원래 가격:`,
        d3: `할인: `,
        d4: `실제 결제`,
        d5: `암호화폐 결제`,
        d6: `고객 서비스`,
        d7: `고객 서비스를 클릭하여 수령 주소를 얻으세요. 결제가 완료되면 이곳으로 돌아와 스크린샷을 업로드하세요.`,
        d8: `구매하기`,
        d9: '해시 값을 입력하세요',

        a1: `› 계정 취소`,
        a2: `계정 취소`,
        a3: `비밀번호:`,
        a4: `비밀번호를 입력하세요`,
        a5: `계정 취소를 확인하려면 현재 계정의 비밀번호를 입력하세요. 계정이 취소되면 로그인할 수 없게 됩니다. 유의하시기 바랍니다.`,
        a6: `확인`,

        cp1: ` › 비밀번호 변경`,
        cp2: `새 비밀번호 설정`,
        cp3: `기존 비밀번호:`,
        cp4: `기존 비밀번호를 입력하세요`,
        cp5: `새 비밀번호:`,
        cp6: `새 비밀번호를 입력하세요`,
        cp7: `비밀번호 확인:`,
        cp8: '확인용 비밀번호를 입력하세요',
        cp9: '확인',

        l1: ` › 언어 설정`,

        mt1: ` › 내 팀`,
        mt2: `초대 링크`,
        mt3: `링크 복사`,
        mt4: `매일 공유하기`,
        mt5: `파트너 초대`,
        mt6: `사용자 이름`,
        mt7: `레벨`,
        mt8: `시간`,

        tle1: ` › 내 칭호`,
        tle2: `초대 링크`,
        tle3: `9.2할인`,
        tle4: '매일 공유하기',
        tle5: '파트너 초대',
        tle6: `총 포인트`,
        tle7: '역할',
        tle8: `1. 수호자 - 브론즈, s1% 포인트가 필요하며 s2% 할인을 받을 수 있습니다.`,
        tle9: `2. 수호자 - 실버, s1% 포인트가 필요하며 s2% 할인을 받을 수 있습니다.`,
        tle10: `3. 수호자 - 골드, s1% 포인트가 필요하며 s2% 할인을 받을 수 있습니다.`,
        tle11: `4. 수호자 - 다이아몬드, s1% 포인트가 필요하며 s2% 할인을 받을 수 있습니다.`,
        tle12: `5. 수호자 - 슈프림, s1% 이상의 포인트가 필요하며 s2% 할인을 받을 수 있습니다.`,
        tle13: `포인트 획득 방법`,
        tle14: `해양 보호 관련 콘텐츠를 공유하고 스크린샷을 Sphere X Fund에 업로드할 때마다, 그리고 Amazon 기프트 카드를 구매할 때마다 1 포인트를 획득하게 됩니다. 포인트가 특정 기준에 도달하면 더 큰 할인을 받을 수 있습니다. 함께 참여하여 해양 보호에 기여해 주세요!`,
        
        v1: ` › 버전 소개`,
        v2: `버전 설명`,
        v3: `버전 번호`,
    },

    inaction: {
        t1: '1단계: 저희 이벤트 포스터를 저장하세요.',
        t2: '포스터 사진을 길게 눌러 앨범에 저장하세요.',
        t3: `2단계: 이 사진을 소셜 플랫폼에 공유하여 더 많은
                        사람들이 해양 생태 보호에 참여하도록 초대하세요.`,
        t4: '공유하기:',
        t5: `공유할 때마다 해양 생태를 향한 관심을 보여줍니다. SXF는 여러분의 선행에 감사드립니다!`,
        t6: `3단계: 소셜 플랫폼에 공유한 이벤트 포스터의 스크린샷을 증거로 제출하세요.
                        리뷰가 완료되면 이벤트에 참여할 수 있습니다. 감사합니다!`,
        t7: '사진을 업로드하세요',
        t8: '지금 공유하기',
    },

    inactX: {
        rejected: '거부됨',
        under: '검토 중',
        approved: '승인됨',
    },

    inbox: {
        t1: '지금 구매',

    },

    login: {
        Username: '사용자 이름',
        LoginPassword: '로그인 비밀번호',
        Registeraccount: '계정 등록',
        Retrievepassword: '비밀번호 찾기',
        login: '로그인',
        loginSS: '성공적으로 로그인했습니다',
        t1: '비밀번호 확인',
        t2: '성명을 입력하세요',
        t3: '전화번호',
        t4: '이메일',
        t5: '코드',
        t6: '초대 코드',
        t7: '등록',
        t8: '취소',
        t9: '등록 페이지',
        t10: '비밀번호 찾기',
    },

    foo: {
        t1: '우리의 글로벌 커뮤니티에 참여하세요',
        t2: `메일링 리스트에 가입하고 소셜 미디어에서 저희와 연결하세요. 함께
                        우리는 동물을 보호하기 위해 세상을 움직이고 있습니다.`,
        t3: `메일링 리스트에 가입하세요`,
        t4: `최신 뉴스와 블로그를 이메일로 받아보세요.`,
        t5: `가입하기`,
        t6: `앱 다운로드`,
        t7: `더 나은 경험, 더 많은 할인`,
        t8: `세계 해양 보호 협회, 미국에 본사를 둔 유한 회사`,
        t9: `등록 번호 20211058723`,
        t10: `4255 South Buckley Road #1296, Aurora, CO 80013, 미국`,
    },

    More: '더 보기',
    onBuy: "현재 구매 조건을 충족하지 않습니다. 공유 후 다시 돌아와 주세요.",
	onBuyed: "오늘 이미 구매하셨습니다. 내일 다시 오세요.",
    copyC: "성공적으로 복사되었습니다",
	copyS: "복사에 실패했습니다",
};