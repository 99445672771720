// 英文
export default {
    home: {
        text1: 'WELCOME TO',
        SXF: 'SXF',
        text2: `This is an urgent action to care for and protect the marine ecology. Here you will learn about
                        protecting the marine ecological environment and marine biodiversity. In addition, you will have
                        the opportunity to participate in our charitable and promotional activities and do our part for
                        the health of our oceans and planet.`,
        text3: 'Contact Us',
        text4: 'Share to:',
        text5: `Our vision is to improve global ocean health, protect marine ecosystems, and
                maintain marine biodiversity. `,
        text6: 'Latest news',
        text7: `Join us for Shark Week 2023 and dive into the world of new research and sustainable
                                shark management.`,
        text8: 'MORE>>',
        
        p1: 'Home › About us',
        p2: `To gain developmental space globally, the Sphere X Fund foundation, guided by its motto "Starting
                    from the Perspective of the Ocean," has chosen a powerful idea and transformed it into three main
                    pillars of action. These pillars will collectively promote education, awareness, participation, and
                    behavioral change. They will also take action to further implement innovative blue economies as well
                    as the protection and restoration of the oceans. In the process, by strengthening democratization
                    and expanding the scale, we will strive for the sustainable utilization of the oceans.`,
        p3: `Home › American Action`,
        p4: `All-American Citizens' Ocean Guardian Action`,
        p5: `Home › ENDANGERED MARINE SPECIES list`,
        p6: `ENDANGERED MARINE SPECIES List`,
        p7: `ENDANGERED MARINE SPECIES`,
        p8: `Welcome to SXF`,
        p9: `Hawaiian monk seal`,
        p10: `Home › JOIN US`,
        p11: `SXF(Association Name)`,
        p12: `Sphere X Fund (SXF) is a non-profit charitable trust organization based in the United States, dedicated to protecting marine ecosystems and preserving the diversity of marine life. SXF has abandoned traditional advertising methods and developed its proprietary promotional strategies. By leveraging technology and community power, it offers tangible rewards to environmental advocates, thereby enhancing awareness and action towards marine conservation.`,
        p13: `Home › MARINE ECOLOGY `,
        p14: `Marine ecology refers to the collective interrelationships among various marine organisms and their interactions with the surrounding environment. Marine ecosystems include components such as seawater, seabed topography, seagrass beds, coral reefs, and plankton, forming a complex and rich ecosystem. Marine ecology plays a crucial role in the Earth's ecological balance by providing food, oxygen, and resources, and influencing climate, global circulation systems, and the carbon cycle. Protecting marine ecosystems is essential for maintaining Earth's ecological balance and sustaining human environments. Currently, marine ecology faces a series of challenges and issues, which will be mainly introduced around the themes of global warming, overfishing, and marine pollution.`,
        


        navhome: "HOME",
        navabout: "ABOUT US",
        navam: "AMERICAN ACTION",
        navjoin: "JOIN US",
        navend: "ENDANGERED MARINE SPECIES",
        navmar: "MARINE ECOLOGY",
        User: 'User Center',

        home: 'HOME',
        Action: 'In Action',
        Sharenow: "Share now",
        ReviewResult: 'Review Result',
        Inbox: 'Inbox',
        Account: 'Account',
    },
    account: {
        t1: 'My Account',
        t2: 'Invitation Code:',
        t3: 'Inviter:',
        t4: 'My Team',
        t5: 'My Title',
        t6: 'My Card',
        t7: 'Change Password',
        t8: 'Language',
        t9: 'Version Introduction',
        t10: 'Delete Account',
        t11: 'Sign Out',

        text: 'Card Details',

        ai1: '› Ai',
        ai2: `Currently only USDT (Trc20) transactions are available.
After transferring, you mustreturn to the previous page(card details) to upload a
transaction screenshot tocomplete the payment!`,
        ai3: `Shipping address (copy the address below)`,

        c1: ` › My Card`,
        c2: 'Number: x',
        c3: 'Payment: Cryptocurrency',
        c4: 'Date:',
        c5: 'Under Review',
        c6: 'Passed',
        c7: 'Rejected',
        c8: 'Card Number:',
        c9: 'Activation Code:',
        c10: 'Rejection Reason:',

        d1: '› Card Details',
        d2: `Original Price:`,
        d3: `Discount: `,
        d4: `Actual Payment`,
        d5: `Cryptocurrency payments`,
        d6: `Al customer service`,
        d7: `Please click Al customer service to obtain the harvest address. After payment is
                            completed,return here toupload a screenshot.`,
        d8: `Buy`,
        d9: 'Enter the hash value',

        a1: `› Account cancellation`,
        a2: `Account cancellation`,
        a3: `Password:`,
        a4: `Please enter password`,
        a5: `Please enter the current account password to confirm the account cancellation. After the account is canceled, you will not be able to log in. Please note`,
        a6: `Confirm`,

        cp1: ` › Change Password`,
        cp2: `Set new password`,
        cp3: `Old Password:`,
        cp4: `Please enter old password`,
        cp5: `New Password:`,
        cp6: `Please enter a new password`,
        cp7: `Confirm Password:`,
        cp8: 'Please enter password to confirm',
        cp9: 'Confirm',

        l1: ` › Language settings`,

        mt1: ` › My Team`,
        mt2: `Invitation Link`,
        mt3: `Copy Link`,
        mt4: `Share Daily`,
        mt5: `Invite Partners`,
        mt6: `Username`,
        mt7: `Level`,
        mt8: `Time`,

        tle1: ` › My Title`,
        tle2: `Invitation Link`,
        tle3: `9.2Discount`,
        tle4: 'Share Daily',
        tle5: 'Invite Partners',
        tle6: `Total Points`,
        tle7: 'Role',
        tle8: `1. Guardian - Bronze, requires s1% points and enjoys an s2% discount.`,
        tle9: `2. Guardian - Silver, requires s1% points and enjoys an s2% discount.`,
        tle10: `3. Guardian - Gold, requires s1% points and enjoys an s2% discount.`,
        tle11: `4. Guardian - Diamond, requires s1% points and enjoys an s2% discount.`,
        tle12: `5. Guardian - Supreme, requires s1% or more points and enjoys an s2% discount.`,
        tle13: `How to earn points`,
        tle14: `Each time you share ocean conservation-related content and upload screenshots to Sphere X Fund, as well as when you purchase Amazon gift cards, you will earn 1 point. When your points reach a certain threshold, you will enjoy greater discounts. Join us and contribute to ocean conservation!`,
        
        v1: ` › Version Introduction`,
        v2: `Version Description`,
        v3: `Version number`,
    },

    inaction: {
        t1: 'Step 1: Please save our event poster.',
        t2: 'Press and hold the poster photo to save it to the album.',
        t3: `Step 2: Please share this photo on our social platforms to invite more
                        people to participate in marine
                        ecological protection<`,
        t4: 'Share to:',
        t5: `Every share you share is a show of care for the marine ecology. SXF
                        thanks you for your kind deeds!`,
        t6: `Step 3: Please submit your proof of screenshots of our event posters
                        shared on social platforms. You
                        can participate in the event after the review is completed. Thank you!`,
        t7: 'Please upload pictures',
        t8: 'Share now',
    },

    inactX: {
        rejected: 'Rejected',
        under: 'Under Review',
        approved: 'Approved',
    },

    inbox: {
        t1: 'Buy Now',

    },

    login: {
        Username: 'Username',
        LoginPassword: 'Login Password',
        Registeraccount: 'Register account',
        Retrievepassword: 'Retrieve password',
        login: 'Login',
        loginSS: 'Sign in successfully',
        t1: 'Confirm Password',
        t2: 'Please enter full name',
        t3: 'Phone number',
        t4: 'Email',
        t5: 'code',
        t6: 'Invitation code',
        t7: 'Register',
        t8: 'Cancel',
        t9: 'Registration page',
        t10: 'Retrieve Password',
    },

    foo: {
        t1: 'Join our global community',
        t2: `Sign up to our mailing list and connect with us on social media.Together we are
                        moving the world to protect animals.`,
        t3: `Sign up to our mailing list`,
        t4: `Get the latest news and blogs by email.`,
        t5: `Sign up`,
        t6: `Download App`,
        t7: `Better experience, more discounts`,
        t8: `World Ocean Conservation Association, a limited company based in`,
        t9: `the United States with registration number 20211058723`,
        t10: `4255 South Buckley Road #1296, Aurora, CO 80013, United States`,
    },




    More: 'More',
    onBuy: "You currently do not meet the purchase conditions, please share and come back later",
	onBuyed: "You have already purchased today, please come back tomorrow",
    copyC: "Copied successfully",
	copyS: "Failed to copy",
};